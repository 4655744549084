import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Component({
	selector: 'app-footer',
	templateUrl: './footer.component.html',
	styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
	currentYear = new Date().getUTCFullYear();
	version = '';

	constructor(private http: HttpClient) {
	}

	ngOnInit() {
		// Get app version
		this.http.get('VERSION', {responseType: 'text'}).subscribe((data) => {
			this.version = data.trim();
		});
	}
}
