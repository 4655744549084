import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { AuthService } from '../auth/auth.service';
import * as queryString from 'query-string';
import { User } from '../auth/user';
import { environment } from '../../../environments/environment';
import { I18nObject } from '../models/I18n.model';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'app-feedback',
	templateUrl: './feedback.component.html',
	styleUrls: ['./feedback.component.scss']
})
export class FeedbackComponent implements OnInit {
	feedbackEnabled: boolean = environment.feedback.enabled;
	baseFeedbackUrl: string = environment.feedback.baseFeedbackUrl;
	safeFeedbackUrl: SafeResourceUrl | null = null;
	isWidgetOpen: boolean = false;
	userData: User | null = null;
	cta: I18nObject = environment.feedback.cta || {en: 'Feedback', es: 'Comentarios'};
	title: I18nObject = environment.feedback.title || {en: 'Feedback', es: 'Comentarios'};
	isProduction: boolean = environment.production;

	@Output() widgetToggle = new EventEmitter<boolean>();

	constructor(
		public sanitizer: DomSanitizer,
		public authService: AuthService,
		public i18n: TranslateService,
	) {
	}

	ngOnInit(): void {
		// Set initial URLs
		this.safeFeedbackUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.baseFeedbackUrl);

		// Get logged user data
		this.authService.userInfo().subscribe((user) => this.userData = user);
	}

	toggleWidget() {
		this.loadFeedbackFrame();
		this.isWidgetOpen = !this.isWidgetOpen;
		this.widgetToggle.emit(this.isWidgetOpen);
	}

	loadFeedbackFrame() {
		// Build preload parameters for feedback form
		const userParams = queryString.stringify({
			'Email Address': this.userData?.email,
			'Reference URL': location.href
		});

		// Update iframe URL
		this.safeFeedbackUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.baseFeedbackUrl + '?' + userParams);
	}
}
