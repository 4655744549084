import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'header-no-auth',
	templateUrl: './header-no-auth.component.html',
	styleUrls: ['./header-no-auth.component.scss']
})
export class HeaderNoAuthComponent implements OnInit {

	constructor() {
	}

	ngOnInit(): void {
	}

}
