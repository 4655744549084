import { Component, ElementRef, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import getIndex, { Option, questionDynamicCatalog } from '../../models/question-model';
import isDefined from '../../../../../core/utils/isDefined';

@Component({
	selector: 'add-tags',
	templateUrl: './add-tags.component.html',
	styleUrls: ['./add-tags.component.scss']
})
export class AddTagsComponent implements OnInit {
	@Input() question: questionDynamicCatalog = {} as questionDynamicCatalog;
	@Input() lang: string = 'en';
	@Input() readonly: boolean = false;
	@Input() disabled: boolean = false;
	@Input() payload: any = {} as any;

	//For repeated inner elements
	@Input() index: number | null = null;

	@Output() valid: EventEmitter<boolean> = new EventEmitter();
	@Output() changeEvent: EventEmitter<any> = new EventEmitter();

	options: Option[] = []
	valueResult: string[] = [];

	separatorKeysCodes: number[] = [ENTER, COMMA];
	tagCtrl = new FormControl();
	filteredTags: Option[] = []; //Observable<Option[]>;
	tags: Option[] = [];
	allTags: Option[] = [];

	@ViewChild('tagInput') tagInput!: ElementRef<HTMLInputElement>;

	debouncedFilter: Subject<any> = new Subject<any>();

	constructor() {
		// this.filteredTags = this.tagCtrl.valueChanges.pipe(
		// 	startWith(null),
		// 	map((term: string | null) => (term ? this._filter(term.toString()) : this.allTags.filter(t => {
		// 		return !this.valueResult.includes(t.value)
		// 	}))),
		// );

		//Change value filter with debounce instead of async
		this.mangeChanges();
		this.tagCtrl.valueChanges.subscribe((value) => {
			this.handleFilter(value);
		});
	}


	ngOnInit() {
	}

	ngOnChanges(changes: SimpleChanges): void {
		//Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
		//Add '${implements OnChanges}' to the class.

		this.allTags = this.question.options || [];

		if (this.payload[this.question.key] !== undefined) {
			if (this.question.maxLength === 1 && this.question.singleValue) {
				// Get value from form data, transform single value to array for internal handling
				this.valueResult = isDefined(this.payload[this.question.key]) ? [this.payload[this.question.key]] : [];
			} else {
				// Get value from form data, or initialize as empty array if null
				this.valueResult = this.payload[this.question.key] || [];
			}

			// Filter tags matching the stored value
			this.tags = this.allTags.filter((option: Option) => this.valueResult.includes(option.value));

			this.valueResult.forEach((value: any) => {
				const tag = this.tags.find((tag) => {
					return tag.value === value;
				});
				if (!tag) {
					this.tags.push({
						value: value,
						label: {
							[this.lang]: value
						},
						dontExist: true
					});
				}
			});
		}
	}

	add(event: MatChipInputEvent): void {
		const value = (event.value || '').trim();

		if (!!value) {
			//Find typed value in the options
			const optionExist = this.allTags?.find((option: Option) => {
				return option.label[this.lang].toLowerCase() === value.toLowerCase()
			})

			// If option exist try to add it
			if (!!optionExist) {
				//Search if value was already added
				const valueExist = this.valueResult.find((value) => {
					return value.toLowerCase() === optionExist?.value.toLowerCase()
				})

				// If value hasn't been added and does exist in options, is added to tags
				if (!valueExist) {
					this.valueResult.push(optionExist.value);
					this.tags.push(optionExist);

					if (this.question.maxLength === 1 && this.question.singleValue) {
						this.changeEvent.emit(this.valueResult.length > 0 ? this.valueResult[0] : null);
					} else {
						this.changeEvent.emit(this.valueResult);
					}
				}
			}
		}

		// Clear the input value
		event.chipInput!.clear();
		this.tagCtrl.setValue(null);
	}

	remove(tag: Option): void {
		const index = this.tags.indexOf(tag);
		const valueIndex = this.valueResult.indexOf(tag.value);

		if (index >= 0) {
			this.tags.splice(index, 1);
			this.valueResult.splice(valueIndex, 1);

			if (this.question.maxLength === 1 && this.question.singleValue) {
				this.changeEvent.emit(this.valueResult.length > 0 ? this.valueResult[0] : null);
			} else {
				this.changeEvent.emit(this.valueResult);
			}
		}

		// Clear the input value
		this.tagCtrl.setValue(null);
	}

	selected(event: MatAutocompleteSelectedEvent): void {
		const newTag: Option = event.option.value;

		// Try to find value to check if it hasn't been added
		const valueExist = this.valueResult.findIndex(value => value === newTag?.value);

		if (valueExist === -1) {
			if (this.question.maxLength !== undefined && this.question.maxLength !== null && this.valueResult.length >= this.question.maxLength) {
				// Remove last element if max length has been reached
				this.valueResult.splice(this.question.maxLength - 1);
			}

			this.tags.push(newTag);
			this.valueResult.push(newTag.value);
		}

		// Clear value input
		this.tagInput.nativeElement.value = '';
		this.tagCtrl.setValue(null);

		if (this.question.maxLength === 1 && this.question.singleValue) {
			this.changeEvent.emit(this.valueResult.length > 0 ? this.valueResult[0] : null);
		} else {
			this.changeEvent.emit(this.valueResult);
		}
	}

	private _filter(value: string): Option[] {
		const filterValue = value?.toLowerCase();

		// Filter tags to those that match the typed search term
		// and isn't already selected
		return this.allTags.filter(t => {
			return (t.label[this.lang] || t.label['en']).toLowerCase().includes(filterValue) && !this.valueResult.includes(t.value)
		});
	}

	handleFilter(event: any) {
		this.debouncedFilter.next({value: event});
	}

	mangeChanges() {
		this.debouncedFilter.pipe(
			debounceTime(500)
		)
			.subscribe({
				next: (event: any) => {
					// console.log('Filter', event);
					this.filteredTags = event?.value ?
						this._filter(event.value)
						: this.allTags.filter(t => {
							return !this.valueResult.includes(t.value);
						});
				},
				error: err => console.error(err)
			});
	}

	protected readonly getIndex = getIndex;
}
