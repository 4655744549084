import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AutoLoginAllRoutesGuard } from 'angular-auth-oidc-client';
import { UnauthorizedComponent } from './core/auth/unauthorized/unauthorized.component';
import { LayoutComponent } from './core/layout/layout.component';
import { NotFoundComponent } from './core/auth/not-found/not-found.component';
import { PermissionsGuard } from './core/guards/permissions.guard';
import { CreateAccountComponent } from './core/onboarding/create-account/create-account.component';
import { LandingPageComponent } from './core/onboarding/landing-page/landing-page.component';
import { AuthGuardGuard } from './core/guards/auth-guard.guard';
import { BaseLayoutComponent } from './core/base-layout/base-layout.component';

const routes: Routes = [
	{
		path: 'login',
		component: BaseLayoutComponent,
		canActivate: [AutoLoginAllRoutesGuard]
	},
	{
		path: '',
		component: LayoutComponent,
		canActivate: [AuthGuardGuard],
		children: [
			{
				path: '',
				loadChildren: () => import('./modules/home/home.module').then((m) => m.HomeModule),
				canActivate: [PermissionsGuard],
				data: {routeName: 'home'}
			},
			{
				path: 'ops',
				loadChildren: () => import('./modules/operations/operations.module').then((m) => m.OperationsModule),
				canActivate: [PermissionsGuard],
				data: {routeName: 'reports'}
			},

			{
				path: 'admin/hubs',
				loadChildren: () => import('./modules/hub-pages/hub-pages.module').then((m) => m.HubPagesModule),
				canActivate: [PermissionsGuard],
				data: {routeName: 'hubs'}
			},

			{
				path: 'analytics/compliance',
				loadChildren: () => import('./modules/compliance/compliance.module').then((m) => m.ComplianceModule),
				canActivate: [PermissionsGuard],
				data: {routeName: 'compliance'}
			},
			{
				path: 'analytics/geoviz',
				loadChildren: () => import('./modules/geoviz/geoviz.module').then((m) => m.GeovizModule),
				canActivate: [PermissionsGuard],
				data: {routeName: 'geoviz'}
			},
			{
				path: 'analytics/metrics',
				loadChildren: () => import('./modules/analytics/analytics.module').then((m) => m.AnalyticsModule),
				canActivate: [PermissionsGuard],
				data: {routeName: 'metrics'}
			},
			{
				path: 'analytics/calendar',
				loadChildren: () => import('./modules/calendar/calendar.module').then((m) => m.CalendarModule),
				canActivate: [PermissionsGuard],
				data: {routeName: 'calendar'}
			},

			{
				path: 'wf',
				loadChildren: () => import('./modules/workflow/workflow.module').then((m) => m.WorkflowModule),
				canActivate: [PermissionsGuard],
				data: {routeName: 'workflows'}
			},
			{
				path: 'networks',
				loadChildren: () => import('./modules/networks/networks.module').then((m) => m.NetworksModule),
				canActivate: [PermissionsGuard],
				data: {routeName: 'networkApplications'}
			},
			{
				path: 'dal',
				loadChildren: () => import('./modules/dal/dal.module').then((m) => m.DalModule),
				canActivate: [PermissionsGuard],
				data: {routeName: 'dal'}
			},
			{
				path: 'admin/dam',
				loadChildren: () => import('./modules/dam/dam.module').then((m) => m.DamModule),
				canActivate: [PermissionsGuard],
				data: {routeName: 'dam'} //TODO: set modules and action for DAM
			},
			{
				path: 'admin/reports',
				loadChildren: () => import('./modules/report-mgmt/report-mgmt.module').then((m) => m.ReportMgmtModule),
				canActivate: [PermissionsGuard],
				data: {routeName: 'report-mgmt'} //TODO: set modules and action for RMG
			},
			{
				path: 'onboarding',
				loadChildren: () => import('./modules/onboarding/onboarding.module').then((m) => m.OnboardingModule),
				canActivate: [PermissionsGuard],
				data: {routeName: 'onboarding'} //TODO: set modules and action for Onboarding selecting organizations
			},
			{
				path: 'admin/users',
				loadChildren: () => import('./modules/org-user-mgmt/org-user-mgmt.module').then((m) => m.OrgUserMgmtModule),
				canActivate: [PermissionsGuard],
				data: {routeName: 'org_user_management'}
			},
			{
				path: 'admin/calendar',
				loadChildren: () => import('./modules/calendar-admin/calendar-admin.module').then((m) => m.CalendarAdminModule),
				canActivate: [PermissionsGuard],
				data: {routeName: 'admin_calendar'}
			},
			{
				path: 'sys',
				loadChildren: () => import('./modules/system/system.module').then(m => m.SystemModule)
			},
		],
	},

	{
		path: 'unauthorized',
		component: UnauthorizedComponent,
	},

	//Onboarding Firsts Steps
	{
		path: 'welcome',
		component: LandingPageComponent,
		canActivate: [AuthGuardGuard],
		data: {module: 'user-registration-onboarding-flow', noAuth: true}
	},
	{
		path: 'register',
		component: CreateAccountComponent,
		canActivate: [AuthGuardGuard],
		data: {module: 'user-registration-onboarding-flow', noAuth: true}
	},

	// Fallback when no prior routes is matched
	{
		path: '**',
		component: LayoutComponent,
		children: [
			{
				path: '',
				component: NotFoundComponent,
			},
		],
	},
];

@NgModule({
	imports: [
		RouterModule.forRoot(routes, {
			useHash: false,
		}),
	],
	exports: [RouterModule],
})
export class AppRoutingModule {
}
