import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { ApiResponse } from '../models/ApiResponse';

@Injectable({
	providedIn: 'root'
})
export class OnboardingService {
	baseURL = `${environment.baseService}${environment.services.cord}`;

	constructor(private http: HttpClient) {
	}

	// Validate if user email is already registered
	validateEmail(dto: Record<string, any>, token: string): Observable<ApiResponse> {
		return this.http.post<ApiResponse>(`${this.baseURL}/public/users/validateEmail`, dto, this.setParams({token: token}));
	}

	// Create a new user account
	createNewAccount(dto: Record<string, any>, token: string): Observable<ApiResponse> {
		return this.http.post<ApiResponse>(`${this.baseURL}/public/users`, dto, this.setParams({token: token}));
	}

	setParams(params: any) {
		let queryParams = new HttpParams();
		if (params != null || params != undefined) {
			Object.keys(params).forEach((keyParam) => {
				if (params[keyParam] != null || params[keyParam] != undefined)
					queryParams = queryParams.append(keyParam, params[keyParam]);
			});
		}
		return {params: queryParams};
	}
}
