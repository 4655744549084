<div class="form-check" *ngFor="let option of optionsChecks;let index = index;">
	<input class="form-check-input"
			type="checkbox"
			[checked]="!!option.isChecked"
		id="{{ question.key}}{{getIndex(index)}}_{{index}}"
			[disabled]="readonly || disabled || question.disabled || false"
			(click)="isChecked($event, index)"
	/>
	<label class="form-check-label" for="{{ question.key}}_{{index}}">
		{{ option.label[lang] || option.label['en'] }}
	</label>
</div>
