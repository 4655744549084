<ng-container [ngSwitch]="question.formula?.valueDisplay">
	<ng-container *ngSwitchCase="'input'">
		<mat-form-field appearance="outline">
			<input
					id="expression_{{question.key}}{{getIndex(index)}}"
				matInput
				[placeholder]="(question.placeholder !== undefined && question.placeholder !== null) ? (question.placeholder[lang] || question.placeholder['en']) : ''"
				[(ngModel)]="resultValue"
				[ngModelOptions]="{standalone: true}"
				readonly
			/>
		</mat-form-field>
	</ng-container>
	<ng-container *ngSwitchDefault>
		<h2>
			{{ this.resultValue }}
			<b *ngIf="question?.configFormula?.showTotal && question?.configFormula?.total">
				/ {{question?.configFormula?.total}} </b>
		</h2>
	</ng-container>
</ng-container>
