import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild, } from '@angular/core';
import { BaseFormElement } from '../models/question-model';
import isDefined from '../../../../core/utils/isDefined';
import { MatDialog } from '@angular/material/dialog';
import { isArray } from 'lodash';

export interface ValueChange {
	key: string,
	value: any
}

@Component({
	selector: 'df-element',
	templateUrl: './df-element.component.html',
	styleUrls: ['./df-element.component.scss'],
})
export class DfElementComponent implements OnInit {
	@Input() payload: any = {};
	@Input() lang: string = 'en';
	@Input() definition!: BaseFormElement;
	@Input() readonly: boolean = false;
	@Input() disabled: boolean = false;
	@Input() extraData: any;
	@Input() isAdmin: boolean = false;
	@Input() formDefinition: BaseFormElement[] = [];

	//For repeated inner elements
	@Input() index: number | null = null;

	@Output() valid: EventEmitter<boolean> = new EventEmitter();
	@Output() changeEvent: EventEmitter<ValueChange> = new EventEmitter();

	totalWords: number = 0;
	maxCharacters: number = 524288;
	minW:number = 0;
	minWords: number = 0;

	emailPattern: RegExp = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

	defaultPattern = '.';

	translations: any = {
		typeHere: {
			'en': 'Type here...',
			'es': 'Escribe aqui...'
		},
		thisIsRequired: {
			'en': 'This field is required',
			'es': 'Este campo es requerido'
		}
	};

	defaultIconHelp: string = 'fa-regular fa-circle-question';
	defaultTitleHelp: any = {en: 'Help', es: 'Ayuda'};
	@ViewChild('helpDialog', {static: true}) helpDialog!: TemplateRef<any>;
	dialogRef: any;

	constructor(public dialog: MatDialog) {
	}

	ngOnInit() {
		this.isValid;
		if (this.definition?.value !== '')
			this.countWords(this.definition.value);


		if (this.definition.autoSelectFirst && this.definition.options !== undefined && this.definition.options.length > 0 && !isDefined(this.definition.value) && this.definition.controlType !== 'dynamic-catalog' && this.definition.controlType !== 'orgSelector') {
			this.definition.value = this.definition.options[0].value;
			this.returnValue(this.definition.options[0].value);
		}
	}

	invalidReasons: string | null = null;
	// @Input() form!: FormGroup;
	// fileTemp;

	get isValid() {
		let valid = true;

		if (!this.definition.readOnly && !this.definition.disabled && !this.disabled && !this.readonly) {
			let conditionType = this.definition.validationRulesLogicalOperator || 'AND';
			let invalidReasonsTemp: string[] = [];

			if (this.definition?.validationRules?.length > 0) {
				let correct = 0;
				let value = this.definition.value;
				const lengthValue = this.definition.value?.length || 0;

				const isArrayValue = !!Array.isArray(value);

				this.definition.validationRules?.forEach((condition: any) => {
					let criteria: any = (isDefined(condition.criteriaKey) ? this.payload[condition.criteriaKey] : condition.criteria) || null;
					let pushReason = false;

					if (isArrayValue && condition.operator != 'includes') {
						value = lengthValue;
					}

					switch (condition.operator) {
						case '=':
							if (value === criteria) correct++;
							else pushReason = true;
							break;
						case '!=':
							if (value != criteria) correct++;
							else pushReason = true;
							break;
						case '>':
							if (value > criteria) correct++;
							else pushReason = true;
							break;
						case '>=':
							if (value >= criteria) correct++;
							else pushReason = true;
							break;
						case '<':
							if (value < criteria) correct++;
							else pushReason = true;
							break;
						case '<=':
							if (value <= criteria) correct++;
							else pushReason = true;
							break;
						case 'includes':
							if (value?.includes(criteria)) correct++;
							else pushReason = true;
							break;
					}

					if (pushReason && (condition.errorLabel[this.lang] || condition.errorLabel['en'])) {
						invalidReasonsTemp.push(
							condition.errorLabel[this.lang] || condition.errorLabel['en']
						);
					}
				});

				if (conditionType == 'AND') {
					if (correct !== this.definition.validationRules.length)
						valid = false;
				} else {
					if (correct == 0)
						valid = false;
				}
			}

			// Required fields - general
			if (this.definition.required && (this.definition.controlType !== 'checkbox' && this.definition.controlType !== 'file' && this.definition.controlType !== 'repeated-content') &&
				(this.definition.value === null || this.definition.value === undefined || this.definition.value.length === 0 || this.definition.value === '' || (isDefined(this.definition.minWords) && this.totalWords < (this.definition?.minWords || 0)))) {
				valid = false;
				invalidReasonsTemp.push(`${this.translations['thisIsRequired'][this.lang] || this.translations['thisIsRequired']['en']} `);
			}

			// Required files
			if (this.definition.required && this.definition.controlType === 'file' && (this.definition.value === null || this.definition.value === undefined || this.definition.value.length === 0)) {
				valid = false;
				invalidReasonsTemp.push(`${this.translations['thisIsRequired'][this.lang] || this.translations['thisIsRequired']['en']} `);
			}

			// Required fields - checkbox
			if (this.definition.required && this.definition.controlType === 'checkbox' && (this.definition.value === null || this.definition.value === undefined || this.definition.value === false)) {
				valid = false;
				invalidReasonsTemp.push('You need to check this box to continue');
			}

			// Email validation
			if (this.definition.type === 'email' && !this.emailPattern.test(this.definition.value) && (this.definition.value != null && this.definition.value != '')) {
				valid = false;
				invalidReasonsTemp.push('Email not valid');
			}

			// Repeated Content Validation
			if (this.definition.required && this.definition.controlType === 'repeated-content') {
				valid = valid && !!this.definition.completed && this.definition.value !== null && this.definition.value !== undefined;
			}

			if (this.definition.type === 'pattern' && !this.isPatterValid(this.definition.value)) {
				valid = false;
				invalidReasonsTemp.push(this.definition.patternError?.[this.lang] || 'Format no correct');
			}

			this.invalidReasons = invalidReasonsTemp.join(conditionType == 'AND' ? ' - ' : ' or ');
		}

		this.definition.valid = valid;
		this.valid.emit(valid);

		return valid;
	}

	returnValue(event: any, element: string = '') {
		let value: any = null;

		switch (element) {
			case 'radiobutton':
				value = this.definition.value;
				break;

			default:
				value = (event !== null && typeof event === 'object' && !isArray(event))
					? 'value' in event && isDefined(event.value)
						? event.value
						: 'target' in event && isDefined(event?.target?.value)
							? event?.target?.value
							: null
					: isDefined(event) ? event : null;

		}

		if (!!value && typeof value === 'string') value = value.trim();

		// Parse boolean values
		if (['true', 'false'].includes(value))
			value = value === 'true';

		switch (this.definition.type) {
			case 'number':
				value = Number(value);
				break;

			case 'currency':
				if (!isNaN(value)) {
					value = `${value}`?.replace(/,/g, '').replace('$ ', '');
					value = parseFloat(value);
				}
				break;

			case 'mask':
				if (value?.includes('(' && ')' && '-')) {
					value = value?.replace(/\D/g, '');
				}
				break;
		}

		// Parse null or empty values
		if (['null', 'NULL', '', '_____', '(___) ___-____', '$ ', NaN].includes(value))
			value = null;

		this.definition.value = value;
		this.emitValue({value: value, key: this.definition.key});
	}

	processCheckbox(event: any) {
		const value = event.checked;
		this.definition.value = value;
		this.emitValue({value: value, key: this.definition.key});
	}

	countWords(value?: any) {
		this.maxCharacters = 524288;

		if (!!this.definition?.limitWords) {
			const maxWords = this.definition?.limitWords || 524288;

			// this.totalWords = !!value ? (`${value}`.match(/ /g) || []).length : 0;
			let words = `${value || ''}`?.trim().split(' ').filter(words => {
				return words !== '';
			});
			this.totalWords = words.length;
			if (value === '')
				this.totalWords = 0;

			if (this.totalWords > maxWords) {
				let newValue = words.splice(0, maxWords).join(' ');
				this.definition.value = newValue;
				this.maxCharacters = newValue?.length;
			}
		}

		if(!!this.definition?.minWords && (!this.definition?.limitWords && !this.definition?.maxLength)){
			let words = `${value || ''}`?.trim().split(' ').filter(words => {
				return words !== '';
			});
			this.totalWords = words.length;
		}
	}

	emitValue(valueChange: ValueChange) {
		console.log('Value Change', valueChange);
		if (isDefined(valueChange.key) && valueChange.key?.toLowerCase() !== 'null' && valueChange.key?.toLowerCase() !== 'undefined')
			this.changeEvent.emit({value: valueChange.value, key: valueChange.key});
	}

	isPatterValid(value: any) {
		const pattern: RegExp = new RegExp(this.definition.pattern || this.defaultPattern);
		console.log(pattern);
		return isDefined(value) && pattern?.test(value);
	}

	openPopupHelp() {
		if (isDefined(this.definition?.helpConfig?.popup)) {
			this.dialogRef = this.dialog.open(this.helpDialog, {});
			this.dialogRef.afterClosed().subscribe(() => {
			});
		}
	}

	getTranslation(textJson: any) {
		return isDefined(textJson) ?
			typeof textJson === 'object'
				? textJson[this.lang] || textJson['en'] || '-'
				: textJson || ' - '
			: ' - ';
	}

	getIndex() {
		return this.index !== null ? `_${this.index}` : '';
	}

	protected readonly isDefined = isDefined;
}
