<span [class]="class">
	{{ bundleId && isDefined(id) ? id + ' - ' : '' }}
	{{ bundleShort && isDefined(name.short) ? name.short + ' / ' : '' }}{{ displayName }}
	<i
		class="fa-solid fa-circle-info"
		style="font-size: 12px"
		*ngIf="prefer !== null && showTooltip && isDefined(name[otherName]) && displayName !== name[otherName]"
		[matTooltip]="name[otherName] || ''"
	></i>
</span>

<ng-container *ngIf="showDownText && isDefined(downText)">
	<br>
	<span class="id-box text-muted">
		{{ downText }}
	</span>
</ng-container>
