import { Component, OnDestroy, OnInit } from '@angular/core';
import { Event, NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import posthog from 'posthog-js';
import { AuthService } from './core/auth/auth.service';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {

	private routeSubscription?: Subscription;

	constructor(
		private router: Router,
		private authService: AuthService
	) {
		// Initialize PostHog analytics integration
		posthog.init('phc_pfDX0VanE9BZL25PnRF5XOLUuNofqc6xAG5egtlacwe', {
			persistence: 'cookie',
			opt_in_site_apps: true,
			api_host: 'https://app.posthog.com'
		});
	}

	ngOnInit(): void {
		// Subscribe to navigation events
		this.routeSubscription = this.router.events.subscribe((event: Event) => {
			if (event instanceof NavigationStart) {
				console.debug('NavigationStart', event);
			}

			if (event instanceof NavigationEnd) {
				console.debug('NavigationEnd', event);

				// Send page view event to PostHog
				posthog.capture('$pageview');
			}

			if (event instanceof NavigationCancel) {
				console.debug('NavigationCancel', event);
			}

			if (event instanceof NavigationError) {
				console.debug('NavigationError', event.error);
			}
		});

		this.authService.isLoggedIn();
	}

	ngOnDestroy() {
		// Unsubscribe to avoid memory leaks
		this.routeSubscription?.unsubscribe();
	}
}
