import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import getIndex, { questionDynamicCatalog } from '../../models/question-model';
import isDefined from '../../../../../core/utils/isDefined';

export interface serviceResponse {
	'data': any;
	'message': string;
	'success': boolean;
}

@Component({
	selector: 'dynamic-catalog',
	templateUrl: './dynamic-catalog.component.html',
	styleUrls: ['./dynamic-catalog.component.scss']
})
export class DynamicCatalogComponent implements OnInit {

	@Input() question: questionDynamicCatalog = {} as questionDynamicCatalog;
	@Input() lang: string = 'en';
	@Input() readonly: boolean = false;
	@Input() disabled: boolean = false;
	@Input() payload: any = {} as any;

	//For repeated inner elements
	@Input() index: number | null = null;

	@Output() valid: EventEmitter<boolean> = new EventEmitter();
	@Output() changeEvent: EventEmitter<any> = new EventEmitter();

	baseURL: string = '';

	dynamicOptions: any[] = [];

	constructor(
		private http: HttpClient
	) {
	}

	ngOnInit(): void {
	}

	ngOnChanges(changes: SimpleChanges): void {
		//Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
		//Add '${implements OnChanges}' to the class.

		this.getCatalog();
	}

	getCatalog() {
		this.baseURL = `${environment.baseService}${environment.services.cord}`;
		const url = this.question?.externalOptions?.url || '';

		switch (this.question?.externalOptions?.method) {
			case 'GET':
				this.serviceGET(url, this.payload).subscribe((res) => {
					this.processResponse(res);
				}, (error) => {
					this.errorActions(`Something went wrong with the server, couldn't obtain catalog`, error);
				});
				break;

			case 'POST':
				this.servicePOST(url, this.payload, {}).subscribe((res) => {
					this.processResponse(res);
				}, (error) => {
					this.errorActions(`Something went wrong with the server, couldn't obtain catalog`, error);
				});
				break;
		}
	}

	processResponse(result: serviceResponse) {
		if (result.success) {
			if (Array.isArray(result.data)) {
				this.dynamicOptions = [];

				if (this.question.prefixOptions?.enable) {
					this.dynamicOptions = [...this.question.prefixOptions?.items.map((element: any) => {
						element.label = this.getLabel(element.label);
						return element;
					})];
				}

				const responseMap = this.question?.externalOptions?.response || {data: 'data', items: {value: null, label: null}};
				const rawDATA = this.getKeyValue(responseMap.data || '', result);

				rawDATA.forEach((elemet: any) => {
					let objData = {
						value: !!responseMap.items.value ? elemet[responseMap.items.value] : '',
						label: this.getLabel(this.getKeyValue(responseMap.items.label || '', elemet)),
					};
					if (!!objData.value) {
						this.dynamicOptions.push(objData);
					}
				});

				if (this.question.suffixOptions?.enable) {
					this.dynamicOptions = [...this.dynamicOptions, ...this.question.suffixOptions?.items.map((element: any) => {
						element.label = this.getLabel(element.label);
						return element;
					})];
				}

				if (this.question.autoSelectFirst && this.dynamicOptions.length > 0 && !isDefined(this.question.value)) {
					this.question.value = this.dynamicOptions[0].value;
					this.returnSelectedValue(this.question.value);
				}

				if (this.dynamicOptions.length === 0) {
					this.errorActions('No Data', '');
				}


			}
		} else {
			this.errorActions(`Something went wrong, couldn't obtain catalog`, result.message);
		}
	}

	getKeyValue(stringKeys: string, json: any) {
		let keys = stringKeys.split('.');
		// if( keys.length > 1 && keys[0] in json){
		//   this.getKeyValue(stringKeys.replace(`${keys[0]}.`, ''), json[keys[0]])
		// }else{
		//   console.log(json[keys[0]])
		//   return json[keys[0]]
		// }

		let value = null;

		for (let index = 0; index < keys.length; index++) {
			if (keys[index] in json && !value) {
				value = json[keys[index]];
			} else if (!!value && keys[index] in value) {
				value = value[keys[index]];
			}
		}

		return value;
	}

	errorActions(message: string, error: any) {
		console.error(`[${this.question.key}] - ${message}`, error);

		this.dynamicOptions = [{
			value: null,
			label: 'NO DATA'
		}];
	}

	serviceGET(url: string, payload: any): Observable<any> {
		let urlComplete = this.insertParams(url, payload);
		return this.http.get<any>(`${urlComplete}`);
	}

	servicePOST(url: string, payload: any, dto: any): Observable<any> {
		let urlComplete = this.insertParams(url, payload);
		return this.http.post<any>(`${urlComplete}`, dto);
	}

	insertParams(url: string, payload: any): string {
		let urlValues = [...url];
		let startParam = false;
		let newParam = '';

		let newUrl = url;

		urlValues.forEach((char: string) => {
			if (!startParam && char === '#') {
				newParam = '';
				startParam = true;
			} else if (char === '#' && !!startParam) {
				startParam = false;
				newUrl = newUrl.replace(`#${newParam}#`, newParam === 'baseURL' ? this.baseURL : this.payload[newParam] || 0);
			}
			;

			if (!!startParam && char !== '#') {
				startParam = true;
				newParam = `${newParam}${char}`;
			}
		});
		return newUrl;
	}

	returnSelectedValue(value: any) {
		switch (this.question.type) {
			case 'number':
				value = Number(value);
				break;
		}

		this.changeEvent.emit({value: value, key: this.question.key});
	}

	getLabel(contentLabel: any): string {
		let label = contentLabel;

		if (typeof label === 'object' && this.lang in label) {
			return label[this.lang];
		}

		return label || '-';
	}

	protected readonly getIndex = getIndex;
}
