import { Injectable } from '@angular/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { map, Observable } from 'rxjs';
import { User } from './user';
import posthog from 'posthog-js';
import { environment } from '../../../environments/environment';
import { UtilsService } from '../../services/utils.service';

@Injectable({
	providedIn: 'root'
})
export class AuthService {
	private userData?: User;
	private localStorageKey: string = 'CORD-USER-INFO';

	constructor(private oidcSecurityService: OidcSecurityService, private utilsService: UtilsService) {
		// Subscribe to session change events and refresh user data
		this.oidcSecurityService.checkSessionChanged$
			.subscribe(sessionChanged => this.userInfo(sessionChanged));

		// Get user data from localstorage
		const ls = localStorage.getItem(this.localStorageKey);

		// Initialize from localstorage if available
		if (ls !== null)
			this.userData = JSON.parse(ls);
	}

	logout() {
		// Reset PostHog session
		posthog.reset();

		// Clear user data
		this.userData = undefined;
		localStorage.removeItem(this.localStorageKey);

		// Terminate app session
		this.oidcSecurityService.logoff();

		localStorage.clear();
		localStorage.removeItem('CORD-USER-INFO');
		localStorage.removeItem('CORD-ORG');
	}

	getUserInfo(): Observable<User> {
		this.oidcSecurityService.userData$.subscribe(({userData}) => {
			// Identify logged-in user in PostHog analytics
			posthog.identify(userData?.sub, {
				name: userData?.fullName,
				email: userData?.email,
				ciamProvider: 'Ping',
				ciamEnvironmentId: userData?.env,
				ciamEnvironmentUri: environment.authConfig.authUri,
				ciamUserId: userData?.sub,
				ciamUsername: userData?.preferred_username,
			});

			console.debug('posthog.identify');
		});

		return this.oidcSecurityService.userData$.pipe(map(({userData}) => {
			// Store user data
			this.userData = {
				firstName: userData?.firstName,
				lastName: userData?.lastName,
				fullName: `${userData?.firstName} ${userData?.lastName}`,
				email: userData?.email,
			};

			// Persist user data to localstorage
			localStorage.setItem(this.localStorageKey, JSON.stringify(this.userData));

			return this.userData;
		}));
	}

	userInfo(refresh = false):Observable<User> {
		// Fetch & store user data if not already in store, or if requested
		if (this.userData?.firstName === undefined || refresh) {
			console.debug(`UserInfo: ${refresh ? 'Refreshing' : 'Fetching'} user data`);
			return this.getUserInfo();
		}

		// Return stored user data
		return new Observable<User>(subscriber => {
			console.debug('UserInfo: Returning stored user data');

			subscriber.next(this.userData);
			subscriber.complete();

			return {
				unsubscribe() {
					// Unsubscribe function doesn't need to do anything as values are delivered synchronously
				}
			};
		});
	}

	isLoggedIn() {
		return this.utilsService.toJSON(localStorage.getItem('localStorageKey'))?.firstName !== undefined;
	}
}
