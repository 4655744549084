<header-no-auth></header-no-auth>

<div class="container-fluid px-5 my-3">
	<div class="row mb-3">
		<div class="col-12 col-sm-6 d-flex align-items-center justify-content-start">
			<h1 class="m-0" style="font-weight: bold">{{ 'onboarding.new_account' | translate }}</h1>
		</div>
	</div>

	<div class="row mb-3" style="margin-bottom: 50px;">
		<div class="col-sm-12">
			<mat-card class="my-2 p-5">
				<mat-card-content>
					<ng-container *ngIf="!validatedEmail; else validatedEmailTemplate">
						<form [formGroup]="userForm" class="row">
							<div class="col-sm-12 col-md-6">
								<ng-container [ngTemplateOutlet]="fieldHeader" [ngTemplateOutletContext]="{
										key: 'email',
										label: 'Email Address',
										description: 'Please enter the email address you use to conduct business',
										hint: 'Personal email address are allowed but not encouraged',
										required: true
									}"></ng-container>

								<mat-form-field appearance="outline">
									<input matInput formControlName="email">
								</mat-form-field>
							</div>

							<ng-container [ngTemplateOutlet]="recaptcha"></ng-container>

							<div class="col-sm-12 text-end">
								<button type="button" [disabled]="!isDefined(token) || userForm?.get('email')?.invalid" (click)="validateEmail()" class="btn btn-success">
									Validate Email
									<i class="fa-solid fa-check ms-2"></i>
								</button>
							</div>
						</form>

						<div class="row col-sm-12 col-md-8" *ngIf="existingEmail">
							<div class="alert alert-warning  d-flex align-items-center" role="alert">
								<i style="font-size: 24px" class="fa-solid fa-circle-info flex-shrink-0 me-3 mb-3 mb-md-0"></i>
								<div>
									<b>Existing Account</b> <br>
									Looks like there is an active CORD account associated with that email,
									please <a [routerLink]="['/']"> <b>sign in </b> </a> instead.
								</div>
							</div>
						</div>
					</ng-container>
				</mat-card-content>
				<mat-card-footer *ngIf="validatedEmail && !accountCreated">
					<div class="row">
						<div class="col-sm-6">
							<button type="button" [disabled]="loadingProcess" (click)="cancelCreateAccount()" class="btn btn-danger mt-2">
								<i class="fa-solid fa-arrow-left me-2"></i>
								Cancel
							</button>
						</div>
						<div class="col-sm-6 text-end">
							<button type="button" [disabled]="userForm.invalid || loadingProcess || !isDefined(token) || !validatePassword()" (click)="createAccount()" class="btn btn-primary mt-2">
								Continue
								<i class="fa-solid fa-arrow-right ms-2"></i>
							</button>
						</div>
					</div>
				</mat-card-footer>
			</mat-card>
		</div>
	</div>
</div>

<ng-template #validatedEmailTemplate>
	<ng-container *ngIf="!accountCreated; else accountCreatedCorrectly">
		<form [formGroup]="userForm" class="row">
			<div class="col-sm-12 col-md-6">
				<ng-container [ngTemplateOutlet]="fieldHeader" [ngTemplateOutletContext]="{
				key: 'email',
				label: 'Email Address',
				description: 'Please enter the email address you use to conduct business',
				hint: 'Personal email address are allowed but not encouraged',
				required: true
			}"></ng-container>
				<mat-form-field appearance="outline" readonly>
					<input readonly matInput formControlName="email">
				</mat-form-field>
			</div>
			<div class="col-sm-12 col-md-6">
				<ng-container [ngTemplateOutlet]="fieldHeader" [ngTemplateOutletContext]="{
				key: 'password',
				label: 'Password',
				description: 'Please enter a password for your new account.',
				hint: 'Minimum 8 characters: must combine uppercase and lowercase letters, numbers, and special characters.',
				required: true
			}"></ng-container>
				<mat-form-field appearance="outline">
					<mat-icon matPrefix [style.color]="validatePassword() ? 'green' : 'red'">{{validatePassword() ? 'check_circle_outline' : 'error_outline'}}</mat-icon>
					<input matInput formControlName="password" [type]="revealPassword ? 'text': 'password'">
					<button type="button" matSuffix mat-icon-button aria-label="Reveal password" (click)="revealPassword=!revealPassword">
						<mat-icon>{{revealPassword ? 'visibility_off' : 'visibility'}}</mat-icon>
					</button>
				</mat-form-field>
			</div>
			<div class="col-sm-12 mt-3 mb-2">
				<ng-container [ngTemplateOutlet]="fieldHeader" [ngTemplateOutletContext]="{
				key: 'name',
				label: 'Name',
				description: 'Please enter your name.',
				required: true
			}"></ng-container>
			</div>
			<div class="col-sm-1">
				<ng-container [ngTemplateOutlet]="fieldHeader" [ngTemplateOutletContext]="{
				key: 'prefix',
				description: 'Prefix',
				required: false
			}"></ng-container>
				<mat-form-field appearance="outline">
					<input matInput formControlName="prefix">
				</mat-form-field>
			</div>
			<div class="col-sm-4">
				<ng-container [ngTemplateOutlet]="fieldHeader" [ngTemplateOutletContext]="{
				key: 'first',
				label: 'First Name',
				required: true
			}"></ng-container>
				<mat-form-field appearance="outline">
					<input matInput formControlName="first">
				</mat-form-field>
			</div>
			<div class="col-sm-2">
				<ng-container [ngTemplateOutlet]="fieldHeader" [ngTemplateOutletContext]="{
				key: 'middle',
				description: 'Middle Name',
				required: false
			}"></ng-container>
				<mat-form-field appearance="outline">
					<input matInput formControlName="middle">
				</mat-form-field>
			</div>
			<div class="col-sm-4">
				<ng-container [ngTemplateOutlet]="fieldHeader" [ngTemplateOutletContext]="{
				key: 'last',
				label: 'Last Name',
				required: true
			}"></ng-container>
				<mat-form-field appearance="outline">
					<input matInput formControlName="last">
				</mat-form-field>
			</div>
			<div class="col-sm-1">
				<ng-container [ngTemplateOutlet]="fieldHeader" [ngTemplateOutletContext]="{
				key: 'suffix',
				description: 'Suffix',
				required: false
			}"></ng-container>
				<mat-form-field appearance="outline">
					<input matInput formControlName="suffix">
				</mat-form-field>
			</div>

			<div class="row col-sm-12 col-md-8">
				<div class="alert alert-primary d-flex align-items-center" role="alert">
					<i style="font-size: 24px" class="fa-solid fa-circle-info flex-shrink-0 me-3 mb-3 mb-md-0"></i>
					<div>
						<b>Important</b> <br>
						CORD accounts are intended for individual use, and account sharing is not allowed.
						<br>
						Each member of your team will need their own account
					</div>
				</div>
			</div>

			<ng-container [ngTemplateOutlet]="recaptcha"></ng-container>
			<p>By continuing with your registration, you are agreeing to these terms and to receive communications regarding your account.</p>
		</form>
	</ng-container>
</ng-template>

<ng-template #accountCreatedCorrectly>
	<div class="alert alert-success d-flex align-items-center" role="alert">
		<i style="font-size: 24px" class="fa-solid fa-circle-check flex-shrink-0 me-3 mb-3 mb-md-0"></i>
		<div>
			<b>Account Created</b> <br>
			Your account was created successfully.
			You will receive a confirmation via email.
		</div>
	</div>
	<a [routerLink]="['/login']" class="btn btn-primary mt-3">
		<i class="fa-solid fa-arrow-right me-2"></i>
		Sign In
	</a>
</ng-template>

<ng-template #fieldHeader let-key="key" let-label="label" let-description="description" let-hint="hint" let-required="required">
	<label [for]="key">
		<span class="field-label">
			<span [innerHTML]="label"></span>
			<span class="text-danger ms-1" *ngIf="required">*</span>
			<span class="sr-only" *ngIf="required">(required field)</span>
		</span>
		<ng-container *ngIf="isDefined(description)">
			<p class="field-description m-0" [innerHTML]="description"></p>
		</ng-container>
		<ng-container *ngIf="isDefined(hint)">
			<p class="field-hint m-0" [innerHTML]="hint"></p>
		</ng-container>
	</label>
</ng-template>

<ng-template #recaptcha>
	<!--Captcha-->
	<div class="row mt-3">
		<div class="col-sm-12 mb-2">
			<re-captcha id="recaptcha" name="recaptcha" #recaptcha="ngModel" [ngModelOptions]="{standalone: true}" [(ngModel)]="token" required [class.is-invalid]="recaptcha.invalid && (recaptcha.dirty || recaptcha.touched)"></re-captcha>
			<div *ngIf="recaptcha.invalid && (recaptcha.dirty || recaptcha.touched)" class="invalid-feedback">
				<div *ngIf="recaptcha.errors?.['required']">This field is required.</div>
			</div>
			<!--<re-captcha [siteKey]="environment.recaptcha.siteKey" size="invisible" #invisible (resolved)="processResponse($event)" name="recaptcha" #recaptcha="ngModel" [ngModelOptions]="{standalone: true}" [(ngModel)]="token"></re-captcha>-->
		</div>
	</div>
	<!--<div class="row">-->
	<!--	<div class="col-sm-12 mb-2">-->
	<!--		<button type="button" class="btn btn-sm btn-primary" (click)="send(userForm)">Send Checkbox</button>-->
	<!--		&lt;!&ndash;<button type="button" class="btn btn-sm btn-primary" (click)="validate()">Send Invisible</button>&ndash;&gt;-->
	<!--	</div>-->
	<!--</div>-->
</ng-template>

<app-footer></app-footer>

<app-feedback (widgetToggle)="onFeedbackWidgetChange($event)"></app-feedback>
