<li class="nav-item dropdown" *ngIf="i18nLangChangeEnabled && languages.length > 1">
	<a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
		<i class="fa fa-globe"></i> {{lang.toString().toUpperCase()}}
	</a>
	<ul class="dropdown-menu">
		<li class="" *ngFor="let language of languages">
			<a href="#" class="dropdown-item" style="cursor: pointer" [class.active]="lang === language.code2" (click)="changeLanguage($event, language.code2)">
				{{language.name}}{{language.localizedName !== language.name ? ' / ' + language.localizedName : ''}}
			</a>
		</li>
	</ul>
</li>
