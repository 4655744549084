import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
	providedIn: 'root'
})
export class PermissionsService {
	baseURL: string = `${ environment.baseService }${ environment.services.cord }`;

	constructor(private http: HttpClient) {
	}

	setPermissions() {
		// Endpoint to get Permissions
		this.getMyPermissions().subscribe((res: any) => {
			if (res.success) {
				const modules = res?.data?.modules || [];
				localStorage.setItem('CORD-MODULES', JSON.stringify(modules));

				const actions = res?.data?.actions || [];
				localStorage.setItem('CORD-ACTIONS', JSON.stringify(actions));

				let menuConfig: any[] = [];
				res.data?.configs?.forEach((rol: any) => {
					menuConfig = [
						...menuConfig,
						...(rol.config?.menu || [])?.filter((key: any) => {
							return !menuConfig.includes(key);
						}) || []
					];
				});
				localStorage.setItem('CORD-MENU', JSON.stringify(menuConfig || []));


			} else {
				console.error(`Couldn't obtain permissions [${ res.message }]`)
			}
		}, (error) => {
			console.error(`Something went wrong with the servicer [${ error }]`)
		})
	}

	// Gets a list of campaigns the logged-in user's orgs have been granted access
	getMyPermissions(): Observable<any> {
		return this.http.get<any>(
			`${this.baseURL}/my/permissions`
		);
	}

	getPermissions(): number[] {
		return JSON.parse(localStorage.getItem('CORD-MODULES') || '[]');
	}

	getActions(): number[] {
		return JSON.parse(localStorage.getItem('CORD-ACTIONS') || '[]');
	}

	hasAllActions(...actions: number[]): boolean {
		// Get role actions
		const roleActions = this.getActions();

		// Check if all queried actions are assigned to the user's role
		return actions.every(action => roleActions.includes(action));
	}

	hasAnyActions(...actions: number[]): boolean {
		// Get role actions
		const roleActions = this.getActions();

		// Check if any of the queried actions are assigned to the user's role
		return actions.some(action => roleActions.includes(action));
	}
}
