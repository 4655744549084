import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApiResponse } from '../../core/models/ApiResponse';

@Injectable({
	providedIn: 'root'
})
export class ReportMgmtService {

	baseURL = `${environment.baseService}${environment.services.cord}`;

	constructor(private http: HttpClient) {
	}

	// Get List of reports by orgID
	getReportsByOrgId(networkId?: any): Observable<ApiResponse> {
		return this.http.get<ApiResponse>(`${this.baseURL}/admin/reports`, this.setParams({networkId: networkId}));
	}

	// Get a list of period by reportID
	getPeriodsByReportId(reportId: number): Observable<ApiResponse> {
		return this.http.get<ApiResponse>(`${this.baseURL}/admin/reports/${reportId}/periods`);
	}

	// Get a list of submissions by reportID and periodID
	getSubmissions(reportId: number, periodId: number, filter: any): Observable<ApiResponse> {
		return this.http.get<ApiResponse>(`${this.baseURL}/admin/reports/${reportId}/periods/${periodId}/submissions`, this.setParams(filter));
	}

	// Get report submission by ID
	getReportSubmission(submissionId: number): Observable<ApiResponse> {
		return this.http.get<ApiResponse>(`${this.baseURL}/admin/reports/submissions/${submissionId}`);
	}

	// Get submission summary by ID
	getSubmissionSummary(submissionId: number): Observable<ApiResponse> {
		return this.http.get<ApiResponse>(`${this.baseURL}/admin/reports/submissions/${submissionId}/summary`);
	}

	//Activities
	// Get list of activities for a given Report Submission ID
	getActivitiesListByReportSubmission(submissionId: number): Observable<ApiResponse> {
		return this.http.get<ApiResponse>(`${this.baseURL}/admin/reports/submissions/${submissionId}/activities`);
	}

	getCampaignsByReport(reportId: any): Observable<ApiResponse> {
		return this.http.get<ApiResponse>(`${this.baseURL}/admin/reports/${reportId}/campaigns`);
	}

	getCampaignsSelectedByActivityId(submissionId: number, activityId: number): Observable<ApiResponse> {
		return this.http.get<ApiResponse>(`${this.baseURL}/admin/reports/submissions/${submissionId}/activities/${activityId}/campaigns`);
	}

	//Get Activity Types by reportID
	getActivityTypes(reportId: any): Observable<ApiResponse> {
		return this.http.get<ApiResponse>(`${this.baseURL}/admin/reports/${reportId}/activityTypes`);
	}

	cancelSubmission(submissionId: any, dto: any): Observable<ApiResponse> {
		return this.http.patch<ApiResponse>(`${this.baseURL}/admin/reports/submissions/${submissionId}/cancel`, dto);
	}

	// Get cancellation reason by report id
	getCancellationReason(reportId: number): Observable<ApiResponse> {
		return this.http.get<ApiResponse>(`${this.baseURL}/admin/reports/${reportId}/cancellationReasons`);
	}

	uncancelSubmission(submissionId: any): Observable<ApiResponse> {
		return this.http.patch<ApiResponse>(`${this.baseURL}/admin/reports/submissions/${submissionId}/uncancel`, {});
	}

	//Attachments
	previewFile(submissionId: any, fileId: number, fileIndex: number): Observable<any> {
		const headers = new HttpHeaders({
			'Content-Type': 'application/json',
			responseType: 'blob'
		});
		return this.http.get<any>(`${this.baseURL}/admin/reports/submissions/${submissionId}/files/${fileId}/${fileIndex}/preview`, {
			headers,
			responseType: 'blob' as 'json'
		});
	}

	downloadFileUrl(submissionId: any, fileId: number, fileIndex: number): Observable<ApiResponse> {
		return this.http.get<ApiResponse>(`${this.baseURL}/admin/reports/submissions/${submissionId}/files/${fileId}/${fileIndex}/fileUrl`);
	}

	downloadFilesZip(submissionId: any): Observable<any> {
		const headers = new HttpHeaders({
			'Content-Type': 'application/json',
			responseType: 'blob'
		});
		return this.http.get<ApiResponse>(`${this.baseURL}/admin/reports/submissions/${submissionId}/files/zip`, {
			headers,
			responseType: 'blob' as 'json'
		});
	}

	getAllFilesBySubmissionId(submissionId: any): Observable<ApiResponse> {
		return this.http.get<ApiResponse>(`${this.baseURL}/admin/reports/submissions/${submissionId}/files`);
	}

	// Get list of files by report ID
	getAllFilesByReportId(reportId: any, periodId: any, filter: any): Observable<ApiResponse> {
		return this.http.get<ApiResponse>(`${this.baseURL}/admin/reports/${reportId}/periods/${periodId}/files`, this.setParams(filter));
	}

	// Get count of all files by report ID
	getCountOfFilesByReportId(reportId: any, periodId: any, filter: any): Observable<ApiResponse> {
		return this.http.get<ApiResponse>(`${this.baseURL}/admin/reports/${reportId}/periods/${periodId}/filesCount`, this.setParams(filter));
	}

	// Download All zip
	downloadAll(reportId: any, periodId: any): Observable<ApiResponse> {
		const headers = new HttpHeaders({
			'Content-Type': 'application/json',
			responseType: 'blob'
		});
		return this.http.get<ApiResponse>(`${this.baseURL}/admin/reports/${reportId}/periods/${periodId}/files/zip`, {
			headers,
			responseType: 'blob' as 'json'
		});
	}

	// Download zip by submissionID
	downloadZipSubmission(submissionId: any): Observable<ApiResponse> {
		const headers = new HttpHeaders({
			'Content-Type': 'application/json',
			responseType: 'blob'
		});
		return this.http.get<ApiResponse>(`${this.baseURL}/admin/reports/submissions/${submissionId}/files/zip`, {
			headers,
			responseType: 'blob' as 'json'
		});
	}

	// Get list of approval report submission
	getApprovalReportSubmission(submissionId: number): Observable<ApiResponse> {
		return this.http.get<ApiResponse>(`${this.baseURL}/admin/reports/submissions/${submissionId}/approvals`);
	}

	// Approval report submission
	createApprovalReportSubmission(submissionId: number, data: any): Observable<ApiResponse> {
		return this.http.post<ApiResponse>(`${this.baseURL}/admin/reports/submissions/${submissionId}/approvals`, data);
	}

	//Delete approval report submission
	deleteApprovalReportSubmission(submissionId: number, approvalId: number): Observable<ApiResponse> {
		return this.http.delete<ApiResponse>(`${this.baseURL}/admin/reports/submissions/${submissionId}/approvals/${approvalId}`);
	}

	// History of approvals
	getHistoryOfApprovals(submissionId: number): Observable<ApiResponse> {
		return this.http.get<ApiResponse>(`${this.baseURL}/admin/reports/submissions/${submissionId}/approvals/history`);
	}

	setParams(params: any) {
		let queryParams = new HttpParams();
		if (params != null || params != undefined) {
			Object.keys(params).forEach((keyParam) => {
				if (params[keyParam] != null || params[keyParam] != undefined)
					queryParams = queryParams.append(keyParam, params[keyParam]);
			});
		}
		return {params: queryParams};
	}
}
