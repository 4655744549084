import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { throwIfAlreadyLoaded } from './guards/module-import.guard';
import { AuthConfigModule } from './auth/auth-config.module';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from 'angular-auth-oidc-client';
import { RouterModule } from '@angular/router';
import { HeaderComponent } from './layout/header/header.component';
import { FooterComponent } from './layout/footer/footer.component';
import { LayoutService } from './services/layout.service';
import { LayoutComponent } from './layout/layout.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatMenuModule } from '@angular/material/menu';
import { MAT_RIPPLE_GLOBAL_OPTIONS } from '@angular/material/core';
import { FeedbackComponent } from './feedback/feedback.component';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSelectModule } from '@angular/material/select';
import { SharedModule } from '../shared/shared.module';
import { RbacInterceptor } from './interceptor/rbac.interceptor';
import { CreateAccountComponent } from './onboarding/create-account/create-account.component';
import { LandingPageComponent } from './onboarding/landing-page/landing-page.component';
import { HeaderNoAuthComponent } from './onboarding/header-no-auth/header-no-auth.component';
import { RECAPTCHA_SETTINGS, RecaptchaFormsModule, RecaptchaModule, RecaptchaSettings } from 'ng-recaptcha';
import { environment } from '../../environments/environment';
import { BaseLayoutComponent } from './base-layout/base-layout.component';

@NgModule({
	declarations: [
		HeaderComponent,
		FooterComponent,
		LayoutComponent,
		FeedbackComponent,
		CreateAccountComponent,
		LandingPageComponent,
		HeaderNoAuthComponent,
		BaseLayoutComponent
	],
	imports: [
		CommonModule,
		SharedModule,
		AuthConfigModule,
		RouterModule,
		MatButtonModule,
		MatIconModule,
		MatToolbarModule,
		MatMenuModule,
		MatSidenavModule,
		MatSelectModule,
		RecaptchaModule,
		RecaptchaFormsModule,
	],
	providers: [
		LayoutService,
		{provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
		{provide: HTTP_INTERCEPTORS, useClass: RbacInterceptor, multi: true},
		{provide: MAT_RIPPLE_GLOBAL_OPTIONS, useValue: {disabled: true}},
		{
			provide: RECAPTCHA_SETTINGS,
			useValue: {
				siteKey: environment.recaptcha.siteKey,
			} as RecaptchaSettings,
		},
	],
	exports: []
})
export class CoreModule {
	constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
		throwIfAlreadyLoaded(parentModule, 'CoreModule');
	}
}
