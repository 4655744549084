import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { TranslateLoader } from '@ngx-translate/core';
import { ApiResponse } from '../core/models/ApiResponse';

@Injectable({
	providedIn: 'root'
})
export class I18nService implements TranslateLoader {

	constructor(private http: HttpClient) {
	}


	listLanguages(): Observable<ApiResponse> {
		return this.http.get<ApiResponse>(`${environment.baseService}${environment.services.cord}/i18n/languages`);
	}

	getAppLanguages(appKey: any): Observable<ApiResponse> {
		return this.http.get<ApiResponse>(`${environment.baseService}${environment.services.cord}/i18n/apps/${appKey}/languages`);
	}

	getTranslation(lang: string): Observable<ApiResponse> {
		return Observable.create((observer: any) => {
			this.http.get<any>(`${environment.baseService}${environment.services.cord}/i18n/apps/portal-ui/languages/${lang}`).subscribe((result: any) => {
				observer.next(result.data);
				observer.complete();
			});
		});
	}
}
