<mat-toolbar color="primary">
	<mat-toolbar-row class="container">
		<button mat-icon-button class="d-sm-block d-md-none" data-bs-toggle="collapse" href="#collapseNavigation" role="button">
			<mat-icon>menu</mat-icon>
		</button>

		<a id="logo" routerLink="" title="Go to home page" class="ms-md-0 ms-auto">CORD</a>

		<mat-divider vertical *ngIf="selectedOrg !== undefined && selectedOrg !== null" class="d-md-block d-none"></mat-divider>

		<ng-container [ngTemplateOutlet]="orgSelector" [ngTemplateOutletContext]="{classes: 'd-md-block d-none'}"></ng-container>

		<div class="ms-auto">
			<button mat-button id="userButton" [matMenuTriggerFor]="userMenu">
				<i class="fa fa-user-circle"></i>
				<p class="d-none d-sm-inline-block">{{ userName }}</p>
			</button>

			<mat-menu #userMenu="matMenu" xPosition="before" yPosition="below">
				<button [routerLink]="['/profile']" mat-menu-item *ngIf="accountProfileEnabled">
					<i class="fa fa-fw fa-user me-2"></i> {{ 'header.account' | translate }}
				</button>

				<button mat-menu-item class="text-danger" (click)="signOut()">
					<i class="fa fa-fw fa-sign-out me-2"></i> {{ 'header.sign_out' | translate }}
				</button>
			</mat-menu>
		</div>
	</mat-toolbar-row>

	<mat-toolbar-row id="navigation" class="bg-secondary d-md-block d-none">
		<div class="container">
			<ng-container [ngTemplateOutlet]="menu"></ng-container>
		</div>
	</mat-toolbar-row>
</mat-toolbar>

<div id="collapseNavigation" class="d-md-none bg-secondary collapse col-12" style="padding: 0 16px;">
	<ng-container [ngTemplateOutlet]="orgSelector" [ngTemplateOutletContext]="{classes: 'd-block py-3'}"></ng-container>
	<ng-container [ngTemplateOutlet]="menu"></ng-container>
</div>

<!-- Org Selector -->
<ng-template #orgSelector let-classes="classes">
	<div style="font-size: 15px" class="{{ classes }} text-white" *ngIf="selectedOrg !== null && orgArray.length === 1">
		<org-name [name]="selectedOrg.name" prefer="short" class="ms-0"></org-name>
	</div>

	<div class="dropdown org-selector text-black {{ classes }}" style="font-size: 15px;" *ngIf="selectedOrg !== null && orgArray.length > 1">
		<button class="btn btn-primary dropdown-toggle" type="button" id="userOrgSelect" data-bs-toggle="dropdown" aria-expanded="false" title="Switch to another organization">
			<org-name [name]="selectedOrg.name" prefer="short" class="ms-0"></org-name>
		</button>
		<ul class="dropdown-menu" aria-labelledby="userOrgSelect">
			<li *ngFor="let org of orgArray">
				<a class="dropdown-item" [class.active]="selectedOrg?.id === org.id" href="#" (click)="selectOrg(org, $event);">
					<span class="badge small rounded me-1" aria-description="Organization ID" title="Org ID">
						{{ org.id }}
					</span>
					<org-name [name]="org.name" [bundleShort]="true" prefer="full" class="ms-0 d-inline"></org-name>
				</a>
			</li>
		</ul>
	</div>
</ng-template>

<!-- "Dynamic" Menu according to permissions -->
<ng-template #menu>
	<ul class="nav mt-0">
		<ng-container *ngFor="let item of menuItems">
			<ng-container *ngIf="!!item?.children && item.children?.length === 0; else subMenu">
				<li class="nav-item" *ngIf="item.active">
					<a data-id="nav_{{item.key}}" class="nav-link" [routerLink]="item.path"> {{ item.label | translate }} </a>
				</li>
			</ng-container>
			<ng-template #subMenu>
				<li class="nav-item dropdown" *ngIf="item.active">
					<a data-id="nav_{{item.key}}" class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
						{{ item.label | translate }}
					</a>
					<ul class="dropdown-menu" *ngIf="!!item?.children && item.children?.length !== 0">
						<ng-container *ngFor="let child of item.children">
							<li *ngIf="child.active && (child.show ? child.show() : true)">
								<a data-id="nav_{{child.key}}" class="dropdown-item" [routerLink]="child.path" routerLinkActive="active">
									{{ child.label | translate }}
								</a>
							</li>
						</ng-container>
					</ul>
				</li>
			</ng-template>
		</ng-container>
		<app-languages class="ms-sm-auto ms-0"></app-languages>
	</ul>
</ng-template>
