import { Component, ElementRef, HostBinding, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { UtilsService } from '../../../services/utils.service';
import { OnboardingService } from '../../services/onboarding.service';
import isDefined from '../../utils/isDefined';
import { Router } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { RecaptchaComponent } from 'ng-recaptcha';
import posthog from 'posthog-js';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'app-create-account',
	templateUrl: './create-account.component.html',
	styleUrls: ['./create-account.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class CreateAccountComponent implements OnInit {
	@HostBinding('class.feedback-open') isFeedbackWidgetOpen: boolean = false;

	onboardingEnabled: boolean = false;

	validatedEmail: boolean = false;
	existingEmail: boolean = false;

	userForm = this.formBuilder.group({
		email: [null, [Validators.required, Validators.email]],
		password: [null, [Validators.required]],
		prefix: [null],
		first: [null, [Validators.required]],
		middle: [null],
		last: [null, [Validators.required]],
		suffix: [null]
	});

	revealPassword: boolean = false;

	loadingProcess = false;
	accountCreated: boolean = false;

	constructor(
		private router: Router,
		private formBuilder: FormBuilder,
		private utilsService: UtilsService,
		private onboardingService: OnboardingService,
		private i18n: TranslateService
	) {
	}

	ngOnInit(): void {
		this.onboardingEnabled = posthog.isFeatureEnabled('user-registration-onboarding-flow', {send_event: false}) || false;
	}

	validateEmail() {
		if (this.userForm.get('email')?.valid) this.validateEmailService();
	}

	validateEmailService() {
		this.validatedEmail = false;
		this.existingEmail = false;

		this.onboardingService.validateEmail({email: this.userForm.get('email')?.value}, this.token).subscribe({
			next: (res) => {
				this.validatedEmail = res.success && !res.data.exist;
				this.existingEmail = res.data.exist;

				posthog.capture('user_registration_check_account', {
					response: {
						success: res.success,
						message: res.message
					},
					email: this.userForm.get('email')?.value,
					existing: res.data.exist,
					i18n: {
						lang: this.i18n.currentLang
					},
					env: {
						app: environment.baseService,
						auth: environment.authConfig.authUri
					},
					source: 'cord-ui'
				});

				if (!res.success) this.utilsService.errorAlert(res.message);
			},
			error: (err) => {
				this.utilsService.errorAlert(null, err);
			},
			complete: () => {
				this.token = null;
			}
		});
	}

	createAccount() {
		this.loadingProcess = true;
		this.accountCreated = false;
		const dto = {
			...this.userForm.value,
			...{phone: null}
		};

		console.log('User', dto);

		this.onboardingService.createNewAccount(dto, this.token).subscribe({
			next: (res) => {
				this.accountCreated = res.success;
				this.loadingProcess = false;

				posthog.capture('user_registration_new_account', {
					response: {
						success: res.success,
						message: res.message
					},
					email: dto.email,
					userId: res.data.uaaUserId,
					i18n: {
						lang: this.i18n.currentLang
					},
					env: {
						app: environment.baseService,
						auth: environment.authConfig.authUri
					},
					source: 'cord-ui'
				});

				if (!res.success) this.utilsService.errorAlert(res.message);
			},
			error: (err) => {
				this.utilsService.errorAlert(null, err);
				this.loadingProcess = false;
			},
			complete: () => {
				this.token = null;
			}
		});
	}

	cancelCreateAccount() {
		this.token = null;
		this.userForm.patchValue({
			password: null,
			prefix: null,
			first: null,
			middle: null,
			last: null,
			suffix: null
		});
		this.validatedEmail = false;


		posthog.capture('user_registration_cancel', {
			i18n: {
				lang: this.i18n.currentLang
			},
			env: {
				app: environment.baseService,
				auth: environment.authConfig.authUri
			},
			source: 'cord-ui'
		});

	}

	onFeedbackWidgetChange(isOpen: boolean) {
		this.isFeedbackWidgetOpen = isOpen;

		if (isOpen)
			document.body.classList.add('feedback-open');
		else
			document.body.classList.remove('feedback-open');
	}

	token: any = null;

	name = 'Angular';
	response: string = '';
	count: number = 0;
	@ViewChild('invisible') invisibleCaptcha!: ElementRef<RecaptchaComponent>;

	validate(): void {
		console.log('Validate captcha');
		console.log(this.invisibleCaptcha);
		// @ts-ignore
		console.log(`Test:`, this.invisibleCaptcha.execute());
		console.log(this.token);
	}

	processResponse(response: any): void {
		console.log(this.count);
		this.response = response;
		this.count++;

		if (this.count === 1) {
			// @ts-ignore
			this.invisibleCaptcha.reset();
			// @ts-ignore
			this.invisibleCaptcha.execute();

			return;
		}

		if (this.count === 2) {
			this.count = 0;

			return;
		}
	}

	validatePassword(): boolean {
		const password: string = this.userForm.get('password')?.value || '';

		// 8-255 characters: at least one uppercase letter, one lowercase letter, one number and one special character (~!@#$%^&*()-_=+[]{}|;:,.<>/?)
		const regex: RegExp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[~!@#$%^&*()\-_=+\[\]{}|;:,.<>/?])[A-Za-z\d~!@#$%^&*()\-_=+\[\]{}|;:,.<>/?]{8,255}$/;

		return regex.test(password);
	}

	protected readonly isDefined = isDefined;
	public readonly environment = environment;
}
