import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import posthog from 'posthog-js';

const modules = [
	{
		'id': 0,
		'routeModule': 'home',
		'alwaysAccess': true
	},
	{
		'id': 1,
		'routeModule': 'announcementManagement',
		'name': {
			'en': 'Admin - Announcement Management'
		},
		'description': {
			'en': 'Manage, review, and approve system announcements.'
		}
	},
	{
		'id': 2,
		'routeModule': 'calendarManagement',
		'name': {
			'en': 'Admin - Calendar Management'
		},
		'description': {
			'en': 'Manage calendar events and their configurations.'
		}
	},
	{
		'id': 3,
		'routeModule': 'dynamicCatalogsManagement',
		'name': {
			'en': 'Admin - Dynamic Catalogs'
		},
		'description': {
			'en': 'Manage dynamic catalogs and their datasets.'
		}
	},
	{
		'id': 4,
		'routeModule': 'geovizManagement',
		'name': {
			'en': 'Admin - GeoViz Management'
		},
		'description': {
			'en': 'Manage resources used in the Geographical Visualization (GeoViz) module.'
		}
	},
	{
		'id': 5,
		'routeModule': 'hubManagement',
		'name': {
			'en': 'Admin - Hub Management'
		},
		'description': {
			'en': 'Manage campaign hubs and their content and configurations.'
		}
	},
	{
		'id': 6,
		'routeModule': 'metricsManagement',
		'name': {
			'en': 'Admin - Metrics Management'
		},
		'description': {
			'en': 'Manage campaign metrics sections and items, and their configurations.'
		}
	},
	{
		'id': 7,
		'routeModule': 'networkManagement',
		'name': {
			'en': 'Admin - Network Management'
		},
		'description': {
			'en': 'Manage organization networks, dynamic forms, workflows, statuses, and related configurations.'
		}
	},
	{
		'id': 8,
		'routeModule': 'operationsManagement',
		'name': {
			'en': 'Admin - Operations Management'
		},
		'description': {
			'en': 'Manage initiatives, campaigns, reports, organization assignments, and related configurations.'
		}
	},
	{
		'id': 9,
		'routeModule': 'publicDashboardManagement',
		'name': {
			'en': 'Admin - Public Dashboard Management'
		},
		'description': {
			'en': 'Manage public dashboards and their items and related configurations.'
		}
	},
	{
		'id': 10,
		'routeModule': 'roleManagement',
		'name': {
			'en': 'Admin - Role Management'
		},
		'description': {
			'en': 'Manage organization user roles and their permissions.'
		}
	},
	{
		'id': 11,
		'routeModule': 'userManagement',
		'name': {
			'en': 'Admin - User Management'
		},
		'description': {
			'en': 'Manage user identities, accounts, organization and role assignments.'
		}
	},
	{
		'id': 12,
		'routeModule': 'announcements',
		'name': {
			'en': 'Announcements'
		},
		'description': {
			'en': 'Access and request publication of informational messages.'
		}
	},
	{
		'id': 13,
		'routeModule': 'calendar',
		'name': {
			'en': 'Calendar'
		},
		'description': {
			'en': 'Access initiative and campaign calendar events.'
		}
	},
	{
		'id': 14,
		'routeModule': 'compliance',
		'name': {
			'en': 'Compliance'
		},
		'description': {
			'en': 'Access compliance information about organizations and their submitted reports.'
		}
	},
	{
		'id': 15,
		'routeModule': 'geoviz',
		'name': {
			'en': 'GeoViz'
		},
		'description': {
			'en': 'Access geographical visualization of system data for analytical and planning purposes.'
		}
	},
	{
		'id': 16,
		'routeModule': 'hubs',
		'name': {
			'en': 'Hubs'
		},
		'description': {
			'en': 'Access campaign hubs and their configured pages.'
		}
	},
	{
		'id': 17,
		'routeModule': 'metrics',
		'name': {
			'en': 'Metrics'
		},
		'description': {
			'en': 'Access campaign metrics reports.'
		}
	},
	{
		'id': 18,
		'routeModule': 'myProfile',
		'name': {
			'en': 'My Profile'
		},
		'description': {
			'en': 'Access user profile information.'
		}
	},
	{
		'id': 19,
		'routeModule': 'networkApplications',
		'name': {
			'en': 'Network Applications'
		},
		'description': {
			'en': 'Access to submit and keep track of network applications.'
		}
	},
	{
		'id': 20,
		'routeModule': 'reports',
		'name': {
			'en': 'Reports'
		},
		'description': {
			'en': 'Access to submit and keep track of campaign reports.'
		}
	},
	{
		'id': 21,
		'routeModule': 'orgManagement',
		'name': {
			'en': 'Sys Admin - Organization Management'
		},
		'description': {
			'en': 'Manage system organizations and their profile information.'
		}
	},
	{
		'id': 22,
		'routeModule': 'workflowManagement',
		'name': {
			'en': 'Admin - Workflow Management'
		},
		'description': {
			'en': 'Manage system workflows, forms and their related configurations.'
		}
	},
	{
		'id': 23,
		'routeModule': 'workflows',
		'name': {
			'en': 'Admin - Workflows'
		},
		'description': {
			'en': 'Access to submit and keep track of workflow submissions.'
		}
	},
	{
		'id': 24,
		'routeModule': 'dam',
		'name': {
			'en': 'Admin - DAM'
		},
		'description': {
			'en': 'Access to digital assets management.'
		}
	},
	{
		'id': 25,
		'routeModule': 'dal',
		'name': {
			'en': 'DAL'
		},
		'description': {
			'en': 'Access to digital assets list.'
		}
	},
	{
		'id': 26,
		'routeModule': 'report-mgmt',
		'name': {
			'en': 'Admin - Reports'
		},
		'description': {
			'en': 'Access to reports management.'
		}
	},
	{
		'id': 27,
		'routeModule': 'org_user_management',
		'name': {
			'en': 'Admin - Org User Management'
		},
		'description': {
			'en': 'Access to the management of the user of the organization.'
		}
	},
];

@Injectable({
	providedIn: 'root'
})
export class PermissionsGuard implements CanActivate {
	constructor(private router: Router, private route: ActivatedRoute) {
	}

	canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot,
	): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		const onboardingEnabled = posthog.isFeatureEnabled('user-registration-onboarding-flow', {send_event: false}) || false;
		const permissions: number[] = JSON.parse(localStorage.getItem('CORD-MENU') || '[]');//JSON.parse(localStorage.getItem('CORD-MODULES') || '[]');

		const hasOrg = JSON.parse(localStorage.getItem('CORD-ORG-ARRAY') || '[]')?.length > 0;
		const actualRoute = route.data['routeName'] || '';

		//GET DATA FROM ACTUAL ROUTE (CHILD)
		const childRoute = route.firstChild;
		const routeIds = childRoute?.data !== null && childRoute?.data !== undefined ? childRoute.data['routeId'] || [] : [];
		const alwaysAccess = childRoute?.data !== null && childRoute?.data !== undefined ? !!childRoute.data['alwaysAccess'] : false;

		if (!hasOrg) {
			// console.log('no org');
			return actualRoute === 'onboarding' || this.router.parseUrl('/onboarding');
		} else if (actualRoute === 'onboarding' && hasOrg) {
			return this.router.parseUrl('');
		}

		// console.log('Permissions Guard', alwaysAccess, routeIds, permissions);
		const hasPermission = !alwaysAccess ? permissions.some((value, index) => {
			return routeIds.includes(value);
		}) : true;

		//Problem with same module
		return hasPermission ? true : this.router.parseUrl('');

		// // Search if the module exist and get the ID
		// const module = modules.find((module) => {
		// 	return module.routeModule === actualRoute;
		// });
		// let hasPermission = !!module?.alwaysAccess;
		//
		// if (permissions.length > 0) {
		// 	// If the module exists verify if that the user has the module assigned
		// 	if (module) hasPermission = module?.alwaysAccess || permissions.includes(module.id);
		// 	else hasPermission = false;
		// }
		//
		// console.log('module Permissions', module, hasPermission);

		// return hasPermission;
	}

}
