import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { I18nService } from '../../../services/i18n.service';
import posthog from 'posthog-js';
import { environment } from '../../../../environments/environment';

@Component({
	selector: 'app-languages',
	templateUrl: './languages.component.html',
	styleUrls: ['./languages.component.scss']
})
export class LanguagesComponent implements OnInit {
	lang: string = localStorage.getItem('CORD-LANG') || 'en';
	languages: any[] = [];
	i18nLangChangeEnabled: boolean = false;

	constructor(private translate: TranslateService, private i18nService: I18nService) {
		// translate.setDefaultLang('en');
	}

	ngOnInit(): void {
		// Wait for feature flags to be available
		posthog.onFeatureFlags(() => {
			// Get whether announcements are enabled
			this.i18nLangChangeEnabled = posthog.isFeatureEnabled('i18n-lang-change', {send_event: false}) || false;

			if (this.i18nLangChangeEnabled) {
				this.getLanguages();

				if (localStorage.getItem('CORD-LANG') === null) {
					this.lang = navigator.language.substring(0, 2);
					localStorage.setItem('CORD-LANG', this.lang);
				}

				this.translate.use(this.lang);
			}
		});

		this.translate.use('en');
	}

	changeLanguage(e: MouseEvent, value: any) {
		e.preventDefault();

		const previousLang = this.lang;
		this.lang = value;
		localStorage.setItem('CORD-LANG', value);
		this.translate.use(value);

		posthog.capture('i18n_change_lang', {
			prevLang: previousLang,
			newLang: this.lang,
			env: {
				app: environment.baseService,
				auth: environment.authConfig.authUri
			},
			source: 'cord-ui'
		});
	}

	getLanguages() {
		this.i18nService.getAppLanguages('portal-ui').subscribe(({success, data}) => {
			if (success) {
				this.languages = data;
			}
		});
	}
}
