import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ProfileService } from '../services/profile.service';
import isDefined from '../utils/isDefined';
import { PermissionsService } from '../../services/permissions.service';
import { UtilsService } from '../../services/utils.service';

@Component({
	selector: 'app-base-layout',
	templateUrl: './base-layout.component.html',
	styleUrls: ['./base-layout.component.scss']
})
export class BaseLayoutComponent implements OnInit {

	redirectURL: string = '/';
	constructor(
		private router: Router,
		private profile: ProfileService,
		private permissionsService: PermissionsService,
		private utilsService: UtilsService,
		private activatedRoute: ActivatedRoute
	) {
	}

	ngOnInit(): void {
		this.activatedRoute.queryParams.subscribe((queryParams) => {

			this.redirectURL = '/';
			if ('go_to' in queryParams) {
				this.redirectURL = queryParams['go_to'] || '/';
				if (Object.keys(queryParams).length > 1) {
					Object.keys(queryParams).forEach((key) => {
						if (key !== 'go_to')
							this.redirectURL += `&${key}=${queryParams[key]}`;
					});
				}
			}

			this.profile.getMyOrgs().subscribe({
				next: ({data, success, message}) => {
					if (success) {
						const hasOrg = data.orgs.length > 0;
						// debugger
						localStorage.setItem('CORD-ORG-ARRAY', JSON.stringify(data.orgs || []));
						// console.log('ORGS base', data.orgs);
						if (hasOrg) {
							// Update selected org
							localStorage.setItem('CORD-ORG', JSON.stringify(data.orgs[0]));
							// Initial load for User-Org settings
							this.getUserOrgConfig();

							this.getPermissions(hasOrg);
						} else {
							this.router.navigate(['/onboarding']);
						}
					} else this.utilsService.errorAlert(message);
				},
				error: (err) => {
					this.utilsService.errorAlert(null, err);
				},
			});
		})
	}

	getPermissions(hasOrg: boolean) {
		console.log('Getting permissions...');
		this.permissionsService.getMyPermissions().subscribe({
			next: ({success, data, message}) => {
				if (success) {
					let menuConfig: any[] = [];
					data?.configs?.forEach((rol: any) => {
						menuConfig = [
							...menuConfig,
							...(rol.config?.menu || [])?.filter((key: any) => {
								return !menuConfig.includes(key);
							}) || []
						];
					});
					localStorage.setItem('CORD-MODULES', JSON.stringify(data?.modules || []));
					localStorage.setItem('CORD-ACTIONS', JSON.stringify(data?.actions || []));
					localStorage.setItem('CORD-MENU', JSON.stringify(menuConfig || []));

					this.router.navigateByUrl(this.redirectURL).then(() => {
					});
				} else
					this.utilsService.errorAlert(message);
			}, error: (error) => {
				this.utilsService.errorAlert(null, error);
				console.error(`Something went wrong with the server trying to get permissions [${error}]`);
			}
		});
	}

	getUserOrgConfig() {
		this.profile.getOrgConfig().subscribe({
			next: (res) => {
				if (res.success) {
					if (isDefined(res.data?.orgNameDisplay))
						localStorage.setItem('CORD-ORG-DISPLAY', res.data?.orgNameDisplay);
				} else {
					console.error('Couldn\'t obtain user config', res.message);
				}
			},
			error: (err) => {
				console.error('Couldn\'t obtain user config', err);
			}
		});
	}
}
