import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, take } from 'rxjs';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import isDefined from '../utils/isDefined';

@Injectable({
	providedIn: 'root'
})
export class AuthGuardGuard implements CanActivate {
	constructor(private router: Router, private oidcSecurityService: OidcSecurityService) {
	}

	canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		return new Promise(resolve => {
			this.oidcSecurityService.checkAuth().pipe(take(1))
				.subscribe(
					(isAuth: any) => {
						if (!isAuth.isAuthenticated)
							localStorage.clear();

						let initialRouteQuery = isDefined(route.queryParams) && 'go_to' in route.queryParams ? `?go_to=${route.queryParams['go_to']}` : !route.data['noAuth'] && isDefined(state.url) ? `?go_to=${state.url}` : '';

						if ('go_to' in route.queryParams && Object.keys(route.queryParams).length > 1) {
							Object.keys(route.queryParams).forEach((key) => {
								if (key !== 'go_to')
									initialRouteQuery += `&${key}=${route.queryParams[key]}`;
							});
						}

						resolve(!route.data['noAuth'] ?
							isAuth.isAuthenticated || this.router.parseUrl('/welcome' + initialRouteQuery)
							: isAuth.isAuthenticated ?
								this.router.parseUrl('/login' + initialRouteQuery)
								: true);
					});
		});
	}

}
