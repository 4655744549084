import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { BaseFormElement, CriteriaKeys, questionDynamicCatalog } from '../../models/question-model';
import isDefined from '../../../../../core/utils/isDefined';

@Component({
	selector: 'formula',
	templateUrl: './formula.component.html',
	styleUrls: ['./formula.component.scss']
})
export class FormulaComponent implements OnInit {
	@Input() question: questionDynamicCatalog = {} as questionDynamicCatalog;
	@Input() lang: string = 'en';
	@Input() readonly: boolean = false;
	@Input() disabled: boolean = false;
	@Input() payload: any = {} as any;

	@Input() definition: BaseFormElement[] = [];

	@Output() valid: EventEmitter<boolean> = new EventEmitter();
	@Output() changeEvent: EventEmitter<any> = new EventEmitter();

	resultValue: any = 0;
	values: any[] = [];

	constructor() {
	}

	ngOnInit(): void {
	}

	ngOnChanges(changes: SimpleChanges): void {
		//Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
		//Add '${implements OnChanges}' to the class.
		setTimeout(() => {
			this.getValues();
		},50)
	}

	getValues() {
		this.resultValue = 0;
		this.values = [];
		const config = this.question.configFormula;
		const defaultPercentage = config?.criteriaKeys?.length && config?.criteriaKeys?.length > 0 ? (this.question.configFormula?.total || 100) / config?.criteriaKeys?.length : 100;

		if (this.payload[this.question.key] !== undefined){
			config?.criteriaKeys.forEach((element: CriteriaKeys) => {
				if (element.key in this.payload) {
					//&& !isNaN(this.payload[element.key])
					// let value = this.payload[element.key] || 0;
					let value = this.getValueFromDefinition(element.key);
					if (config.formula === 'percentage' && config?.total)
						value = value * (element?.percentage || defaultPercentage) / (element.maxValue || config?.total);

					this.values.push(value);
				}
			});

			this.resultValue = this.values.reduce((a, b) => a + b, 0);
			if (Number(this.payload[this.question.key]) !== this.resultValue) {
				switch (config?.formula) {
					case 'sum':
						// this.resultValue = this.values.reduce((a, b) => a + b, 0)
						break;
					case 'percentage':
						this.resultValue = this.resultValue?.toFixed(2);
						break;
				}
				this.changeEvent.emit(Number(this.resultValue));
			}
			else {
				this.resultValue = this.payload[this.question.key] || 0
			}
		}
	}

	getValueFromDefinition(key: string) {
		const DEFINITION = this.definition || [];

		const step = DEFINITION.find((step: any) => {
			return step.content.some((field: any) => {
				return field.key === key;
			});
		});

		const field: any = step?.content?.find((item: any) => {
			return item.key === key;
		}) || {};

		return isDefined(field) ?
			field.options?.length > 0
				? field.options.map((f: any) => {
					return this.payload[key]?.includes(f.value) ? f.score : 0;
				}).reduce((a: any, b: any) => a + b, 0)
				: key in this.payload
					? this.payload[key]
					: 0
			: 0;
	}
}
