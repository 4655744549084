<mat-form-field appearance="outline">
	<input
		#filterBox
		matInput
		type="text"
		aria-label="Select an organization"
		aria-description="Start typing to search for an organization and then select it"
		[id]="'org_search_'+question.key+getIndex(index)"
		[placeholder]="isDefined(question.placeholder) ? question.placeholder[lang] : 'Select an organization'"
		[readonly]="readonly"
		[disabled]="disabled"
		[formControl]="orgSelected"
		[matAutocomplete]="orgAutocomplete"
		(click)="filterBox.select()"
	>
	<button type="button" id="btn_clear_org_{{question.key}}{{getIndex(index)}}" class="btn clearSearch" title="Clear search" (click)="orgSelected.setValue(null)" *ngIf="isDefined(orgSelected.value)">
		<i class="fa fa-times-circle text-danger"></i>
	</button>

	<mat-autocomplete #orgAutocomplete="matAutocomplete" autoActiveFirstOption panelWidth="auto" [displayWith]="autocompleteDisplay">
		<mat-option
			*ngFor="let option of filteredOptions | async"
			[value]="option"
				id="org_option{{getIndex(index)}}_{{option.id}}"
		>
			<org-name
				class="m-0"
				[id]="option.id"
				[name]="option.name"
				[bundleId]="!!question?.orgSelectorConfig?.bundleId"
				[bundleShort]="!!question?.orgSelectorConfig?.bundleShort"
				[showTooltip]="true"
			></org-name>
		</mat-option>
	</mat-autocomplete>
</mat-form-field>
