<a href="https://support.cordhub.org" target="_blank" class="btn" id="toggle" *ngIf="isProduction && feedbackEnabled">
	<span>Support</span>
</a>

<button type="button" class="btn" id="toggle" (click)="toggleWidget()" *ngIf="!isProduction && feedbackEnabled">
	<span>{{ cta[i18n.currentLang] || cta['en'] }}</span>
</button>

<aside *ngIf="isWidgetOpen">
	<header class="d-flex align-items-center">
		<span>{{ title[i18n.currentLang] || title['en'] }}</span>
		<button type="button" class="btn btn-link ms-auto" title="Close side panel" (click)="toggleWidget()">
			<i class="fa fa-fw fa-lg fa-times"></i>
		</button>
	</header>

	<div class="loader">
		<i class="fa fa-circle-notch fa-spin fa-3x fa-fw"></i>
	</div>

	<iframe [src]="safeFeedbackUrl"></iframe>
</aside>
