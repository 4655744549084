import {Component, OnInit} from '@angular/core';
import {AuthService} from "../auth.service";
import {Router} from "@angular/router";

@Component({
	selector: 'app-unauthorized',
	templateUrl: './unauthorized.component.html',
	styleUrls: ['./unauthorized.component.scss']
})
export class UnauthorizedComponent implements OnInit {

	constructor(private authService: AuthService, private router: Router,) {
	}

	ngOnInit(): void {
	}

  logout(){
    // if user is not unauthorized the app ID claim will not be included therefore we will get token hint error:
    // https://support.pingidentity.com/s/question/0D51W00008Z4uCFSAZ/receive-error-invalid-id-token-hint-invalid-jws-signature-when-logging-out-using-angularoauth2oidc
    // TODO: to fix it we just log out locally or end the session using the api
    // this.authService.logout();
  }

}
