import { NgModule } from '@angular/core';
import { AbstractSecurityStorage, AuthModule, LogLevel } from 'angular-auth-oidc-client';
import { environment } from '../../../environments/environment';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { AuthStorageService } from './auth-storage.service';
import { RouterModule } from '@angular/router';

@NgModule({
	imports: [
		AuthModule.forRoot({
			config: {
				authority: `${environment.authConfig.authUri}/as`,
				redirectUrl: `${window.location.origin}${environment.authConfig.redirectUrl}`,
				postLogoutRedirectUri: `${window.location.origin}${environment.authConfig.redirectUrl}welcome`,
				clientId: environment.authConfig.clientId,
				scope: environment.authConfig.scopes,
				responseType: 'code',
				silentRenew: true,
				useRefreshToken: true,
				renewTimeBeforeTokenExpiresInSeconds: 300,
				logLevel: LogLevel.Error,
				secureRoutes: [`${environment.baseService}${environment.services.cord}`],
			},
		}),
		RouterModule,
	],
	providers: [
		{provide: AbstractSecurityStorage, useClass: AuthStorageService}
	],
	exports: [AuthModule],
	declarations: [
		UnauthorizedComponent,
		NotFoundComponent,
	],
})
export class AuthConfigModule {
}
