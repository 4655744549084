import { Injectable } from '@angular/core';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';
import { I18nObject } from '../core/models/I18n.model';
import { OrgNameType } from '../core/models/System.model';
import posthog from 'posthog-js';
import { environment } from '../../environments/environment';
import isDefined from '../core/utils/isDefined';

type ErrorCodeConfig = Omit<SweetAlertOptions, 'title' | 'html'> & { title: I18nObject, html: I18nObject };
type ErrorCodeMapping = Record<string, ErrorCodeConfig>;

@Injectable({
	providedIn: 'root'
})
export class UtilsService {

	errorCodes: ErrorCodeMapping = {
		'permission_denied': {
			icon: 'error',
			title: {
				en: 'Permission Denied',
			},
			html: {
				en: 'You don\'t have access to perform the requested action. Please consult with your CORD administrator if you think this is an error.',
			}
		},

		'submission_already_active': {
			icon: 'warning',
			title: {
				en: 'Existing Application',
			},
			html: {
				en: 'Your organization already has an active application for this network. Please check the <a href="networks/applications">My Network Applications</a> page for more information.',
			}
		},

		'submission_already_cancelled': {
			icon: 'warning',
			title: {
				en: 'Withdraw Application',
			},
			html: {
				en: 'The requested application was already cancelled. No changes were made. <br/><br/> Please refresh the page to see the latest information.',
			}
		},

		'uaa_user_not_found': {
			icon: 'error',
			title: {
				en: 'User Not Found',
			},
			html: {
				en: 'The requested user account was not found.<br/>Please verify your data is correct and try again.',
			},
		},

		'profile_locked': {
			icon: 'error',
			title: {
				en: 'Changes Were Not Saved',
			},
			html: {
				en: 'Your network profile is no longer editable because the deadline has passed.<br/><br/>Please contact your network administrator with any questions.',
			},
		},

		'attachment_locked': {
			icon: 'error',
			title: {
				en: 'Changes Were Not Saved',
			},
			html: {
				en: 'The requested attachment is no longer editable because its deadline has passed.<br/><br/>Please contact your network administrator with any questions.',
			},
		},

		'report_submission_cancel_approval_lock': {
			icon: 'error',
			title: {
				en: 'Submission Locked',
			},
			html: {
				en: 'The requested submission is no longer cancelable as it has been approved and locked by the network owner.<br/><br/>Please contact your network administrator with any questions.',
			},
		},

		'user_exists': {
			icon: 'error',
			title: {
				en: 'Existing User Account',
			},
			html: {
				en: 'Our records show that there is already a user account with the provided email address. If you own that account, please <a href="/portal/login">sign in</a> instead.<br><br>Otherwise, please use a different email address to register a CORD account.',
			},
		},

		'password': {
			icon: 'error',
			title: {
				en: 'Password Strength',
			},
			html: {
				en: `The password you entered does not seem to be strong enough. Please try again with a different password, considering the following criteria:<br>
					<ul class="small mt-4 text-start list-group">
						<li class="list-group-item">It must not contain any of your profile information (e.g. First, Last Names, Email).</li>
						<li class="list-group-item">It cannot have more than 2 repeated characters.</li>
						<li class="list-group-item">It must have a minimum of 5 unique characters.</li>
						<li class="list-group-item">It must have at least 1 lowercase letter.</li>
						<li class="list-group-item">It must have at least 1 uppercase letter.</li>
						<li class="list-group-item">It must have at least 1 number.</li>
						<li class="list-group-item">It must have at least 1 of the following special characters: <code class="bg-light border p-1 rounded" style="letter-spacing: 5px;">~!@#$%^&*()-_=+[]{}|;:,.<>/?</code>.</li>
						<li class="list-group-item">Your password will be checked against a list of commonly used passwords and won't be allowed if a match is found.</li>
					</ul>`,
			},
		},

		// TODO: Error message --> 'network_not_found': {},
		// TODO: Error message --> 'form_not_found': {},
		// TODO: Error message --> 'net_membership_disabled': {},
		// TODO: Error message --> 'form_not_published': {},
	};

	constructor(
		private i18n: TranslateService
	) {
	}

	showLoading(message: string) {
		Swal.fire({
			title: `${message}`,
			timerProgressBar: true,
			showCancelButton: false,
			showConfirmButton: false,
			allowOutsideClick: false,
			allowEscapeKey: false,
			heightAuto: false, // Prevents alert scroll height reset
			didOpen: () => {
				Swal.showLoading();
			},
		}).then();
	}


	IsJsonString(str: any) {
		if (!str) {
			return false;
		}
		try {
			JSON.parse(str);
		} catch (e) {
			return false;
		}
		return true;
	}

	toJSON(value: any) {
		return this.IsJsonString(value) ? JSON.parse(value) : value;
	}

	errorAlert(msg: any, serverError?: any) {
		Swal.close();

		posthog.capture('error_alert', {
			message: msg,
			serverError: serverError,
			i18n: {
				lang: this.i18n.currentLang
			},
			env: {
				app: environment.baseService,
				auth: environment.authConfig.authUri
			},
			source: 'cord-ui'
		});

		// Get error message from server response
		if (msg === undefined || msg === null)
			msg = serverError?.error?.message;

		// Get alert configuration or default one
		const alert: ErrorCodeConfig = msg in this.errorCodes ? this.errorCodes[msg] : {
			icon: 'error',
			title: {en: 'Something went wrong'},
			html: {en: 'We found an error when trying to process your request. Please reload this page and try again in a couple minutes.<br/><br/>If problems persist, please contact your CORD administrator. <pre class="text-muted text-center p-2 d-block mt-4 bg-light" style="font: 11px sans-serif;">#ERROR#</pre>'},
		};

		Swal.fire({
			icon: alert.icon,
			title: alert.title[this.i18n.currentLang] || alert.title['en'],
			html: (alert.html[this.i18n.currentLang] || alert.html['en']).replace(/#ERROR#/g, `${serverError?.status !== undefined ? `HTTP ${serverError.status}` : ''} - ${serverError?.error?.message !== undefined ? `${serverError.error.message}` : ''}`),
		}).then();
		console.error(msg);
	}

	getOrgNameDisplay_Config(): OrgNameType {
		return localStorage.getItem('CORD-ORG-DISPLAY') || this.toJSON(localStorage.getItem('CORD-ORG'))?.config?.users?.orgNameDisplay || 'full';
	}

	getSelectedOrgNameDisplay(orgName: any): string {
		const display = this.getOrgNameDisplay_Config();
		return orgName[display] || orgName[display != 'legal' ? 'legal' : 'full'] || orgName['short'] || null;
	}

	getI18nString(object: I18nObject, defaultValue?: string | I18nObject) {
		const getDefaultValue = () => {
			if (defaultValue !== undefined) {
				if (typeof defaultValue === 'string') {
					return defaultValue;
				} else {
					return defaultValue[this.i18n.currentLang] || defaultValue['en'] || '';
				}
			} else return '';
		};

		if (!isDefined(object)) return getDefaultValue();
		return object[this.i18n.currentLang] || object['en'] || getDefaultValue();
	}
}
