import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable()
export class RbacInterceptor implements HttpInterceptor {

	constructor() {
	}

	intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
		// Only add RBAC header to CORD API requests
		if (request.url.startsWith(environment.baseService)) {
			// Get stored Org ID
			const org = JSON.parse(localStorage.getItem('CORD-ORG') || 'null');

			// Check user has selected an organization
			if (org !== null) {
				// Add CORD Org ID header
				return next.handle(request.clone({
					setHeaders: {
						'CORD-ORG-ID': [org.id],
					}
				}));
			}
		}

		// Resume request handling
		return next.handle(request);
	}
}
