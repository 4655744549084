import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { DfStepComponent } from './components/dynamic-form/df-step/df-step.component';
import { DfElementComponent } from './components/dynamic-form/df-element/df-element.component';
import { DfFormComponent } from './components/dynamic-form/df-form/df-form.component';
import { IConfig, NgxMaskModule } from 'ngx-mask';
import { MaterialModule } from './components/material/material.module';
import { FileComponent } from './components/file/file.component';
import { CarouselComponent } from './components/carousel/carousel.component';
import { AddLanguageComponent } from './components/add-language/add-language.component';
import { GetCsvBtnComponent } from './components/get-csv-btn/get-csv-btn.component';
import { MultipleCheckboxComponent } from './components/dynamic-form/components/multiple-checkbox/multiple-checkbox.component';
import { MultipleInputComponent } from './components/dynamic-form/components/multiple-input/multiple-input.component';
import { NgJsonEditorModule } from 'ang-jsoneditor';
import { NgApexchartsModule } from 'ng-apexcharts';
import { DynamicCatalogComponent } from './components/dynamic-form/components/dynamic-catalog/dynamic-catalog.component';
import { ContainerComponent } from './components/dynamic-form/components/container/container.component';
import { MapComponent } from './components/map/map.component';
import { LanguagesComponent } from './components/languages/languages.component';
import { TranslateModule } from '@ngx-translate/core';
import { AddTagsComponent } from './components/dynamic-form/components/add-tags/add-tags.component';
import { NgxFileDropModule } from 'ngx-file-drop';
import { UserNameComponent } from './components/user-name/user-name.component';
import { NgxPrintModule } from 'ngx-print';
import { FormulaComponent } from './components/dynamic-form/components/formula/formula.component';
import { OrgNameComponent } from './components/org-name/org-name.component';
import { NetworkSelectorComponent } from './components/network-selector/network-selector.component';
import { InitiativesSelectorComponent } from './components/initiatives-selector/initiatives-selector.component';
import { OrgSearchComponent } from './components/org-search/org-search.component';
import { ExpressionComponent } from './components/dynamic-form/components/expression/expression.component';
import { RepeatedContentComponent } from './components/dynamic-form/components/repeated-content/repeated-content.component';
import { MultipleLanguageComponent } from './components/multiple-language/multiple-language.component';

export const options: Partial<IConfig | null> | (() => Partial<IConfig>) = null;

@NgModule({
	declarations: [
		DfStepComponent,
		DfElementComponent,
		DfFormComponent,
		FileComponent,
		CarouselComponent,
		AddLanguageComponent,
		GetCsvBtnComponent,
		MultipleCheckboxComponent,
		MultipleInputComponent,
		DynamicCatalogComponent,
		ContainerComponent,
		MapComponent,
		LanguagesComponent,
		AddTagsComponent,
		UserNameComponent,
		FormulaComponent,
		OrgNameComponent,
		NetworkSelectorComponent,
		InitiativesSelectorComponent,
		OrgSearchComponent,
		ExpressionComponent,
		RepeatedContentComponent,
  MultipleLanguageComponent
	],
	imports: [
		CommonModule,
		NgxMaskModule.forRoot(),
		MaterialModule,
		NgApexchartsModule,
		TranslateModule,
		NgxFileDropModule,
		NgxPrintModule
	],
	exports: [
		NgxMaskModule,
		DfFormComponent,
		DfElementComponent,
		MaterialModule,
		FileComponent,
		CarouselComponent,
		AddLanguageComponent,
		GetCsvBtnComponent,
		NgJsonEditorModule,
		NgApexchartsModule,
		MapComponent,
		LanguagesComponent,
		TranslateModule,
		UserNameComponent,
		OrgNameComponent,
		NetworkSelectorComponent,
		InitiativesSelectorComponent,
		MultipleLanguageComponent
	],
	providers: [DatePipe]
})
export class SharedModule {
}
