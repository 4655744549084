import { I18nObject } from '../../../../core/models/I18n.model';
import { TooltipPosition } from '@angular/material/tooltip';

export interface BaseFormElement {
	uuid?: string;
	controlType:
		| 'step'
		| 'textbox'
		| 'html'
		| 'container'
		| 'dropdown'
		| 'checkbox'
		| 'radiobutton'
		| 'file'
		| 'signature'
		| 'button'
		| 'valueText'
		| 'tags'
		| 'dynamic-catalog'
		| 'multiple-checkbox'
		| 'repeated-content'
		| 'formula'
		| 'orgSelector';
	valid?: boolean;
	value?: any | any[];
	key: string;
	label: I18nObject | null;
	description: I18nObject | null;
	content?: BaseFormElement[];
	required?: boolean;

	/** @deprecated New property: `validationRules` */
	requiredConditions?: ValidationRule[];
	/** @deprecated New property: `validationRulesLogicalOperator` */
	requiredConditionsType?: 'AND' | 'OR';

	validationRules: ValidationRule[];
	validationRulesLogicalOperator?: 'AND' | 'OR';

	type?: string;
	options?: Option[];
	colspan: Array<number | null>;
	colClass: string | null;

	/** @deprecated New property: `conditional` */
	conditionQuestion?: boolean;
	/** @deprecated New property: `conditionRulesLogicalOperator` */
	conditionQuestionType?: 'AND' | 'OR';
	/** @deprecated New property: `conditionRules` */
	conditions?: ConditionRule[];

	conditional?: boolean;
	conditionRulesLogicalOperator?: 'AND' | 'OR';
	conditionRules?: ConditionRule[];

	dynamicOptions?: string;
	placeholder?: any;
	readOnly?: boolean;
	maxLength?: number;
	minWords?: number;
	formula?: FormulaConfiguration;
	roles?: string[];
	disabled?: boolean;
	style?: string;
	mask?: string;
	limitWords?: number;
	allowDuplicated?: boolean;
	limitToOptions?: boolean;
	configFormula?: ConfigFormula;

	//Selection elements
	autoSelectFirst?: boolean;

	// Files
	multipleFiles?: boolean;
	extensionsAllowed?: string[];
	maxSize?: number;

	// Tags
	singleValue?: boolean;

	//OrgSelector
	orgSelectorConfig?: orgSelectorConfig;

	//Pattern
	pattern?: string;
	patternError?: I18nObject | null;

	//Repeated Content
	reference?: any;
	element_colspan?: Array<number | null>;
	element_colClass?: string | null;
	completed?: boolean;
	//for numeric elements
	showLabel?: boolean;
	showLabelIndex?: boolean;

	//tooltip
	helpConfig?: {
		tooltip?: I18nObject;
		tooltipPosition?: TooltipPosition;
		popup?: I18nObject;
		icon?: string;
		title?: I18nObject;
	};
}

export interface questionDynamicCatalog extends BaseFormElement{
	prefixOptions?: {
		enable: boolean,
		items: Option[]
	},
	externalOptions?: {
		url: string,
		method: "GET" | "POST"
		body?: {
			key:{
				location:string,
				path:string
			}
		},
		response: {
			data: string,
			items: {
				value: string,
				label: string
			}
		}
	},
	suffixOptions?: {
		enable: boolean,
		items: Option[]
	}
}

export type ValidationRule = {
	criteria?: any;
	criteriaKey?: string;
	operator: string;
	errorLabel: dynamicObject;
};

export interface ConditionRule extends ValidationRule {
	questionKey: string;
	criteriaKey?: string;
	innerReference?: boolean;
}

export type Option = {
	value: any;
	label: I18nObject;
	score?: number;
	dontExist?: boolean;
};

export type dynamicObject = {
	[key: string]: any;
};

export interface step extends BaseFormElement {
	show: boolean;
	form?: dynamicObject;
	title?: I18nObject;
	description: I18nObject | null;
	active?: boolean;
}

export interface ConfigFormula {
	showTotal: boolean;
	formula: 'sum' | 'percentage';
	criteriaKeys: CriteriaKeys[];
	total?: number;
}

export interface CriteriaKeys {
	key: string;
	percentage?: number;
	maxValue?: number;

	//expression
	childKey?: string;
}

export interface orgSelectorConfig {
	networkIdKey: string;
	bundleId?: boolean;
	bundleShort?: boolean;
}

export interface FormulaConfiguration {
	valueDisplay: 'text' | 'input' | 'input-currency';
	total?: number;
	showTotal?: boolean;
	expression: string;
	criteriaKeys: CriteriaKeys[];
}

/**
 * Return index as string if is defined
 * @param index Value to check.
 */
const getIndex = (index: any) => index !== null ? `_${index}` : '';

export default getIndex;


