<mat-toolbar color="primary">
	<mat-toolbar-row class="container">
		<a id="logo" routerLink="" title="Go to home page" class="ms-md-0 ms-auto">CORD</a>
	</mat-toolbar-row>

	<mat-toolbar-row id="navigation" class="bg-secondary d-md-block d-none">
		<div class="container">
			<ul class="nav mt-0">
				<li class="nav-item">
					<a class="nav-link" [routerLink]="'/welcome'"> {{ 'onboarding.nav_welcome' | translate }} </a>
				</li>
			</ul>
		</div>
	</mat-toolbar-row>
</mat-toolbar>
