<div class="row ">
	<div class="{{ question.element_colClass || 'col-sm-12' }}" *ngFor="let content of values; let index = index">
		<div class="row mt-1 m-0 p-0  content-item">
			<h3 *ngIf="repeatedType === 'array'" class="col-12 mt-3 mb-0 pb-0"> {{getTranslation(content.label)}} </h3>
			<ng-container *ngFor="let field of content.definition; let indexInner = index">
				<div class="{{ field.colClass || 'col-sm-12' }} pe-1 mt-0 pt-0">
					<df-element
						[index]="index"
						[lang]="lang || 'en'"
						[payload]="content.payload"
						[extraData]="extraData"
						[definition]="field"
						[readonly]="readonly"
						[disabled]="disabled"
						(valid)="processValid($event, index, indexInner)"
						(changeEvent)="dataChanged($event, content.index)"
					></df-element>
				</div>
			</ng-container>
		</div>
	</div>
</div>
