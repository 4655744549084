import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { OrgName, OrgNameType } from '../../../core/models/System.model';
import isDefined from '../../../core/utils/isDefined';
import { UtilsService } from '../../../services/utils.service';

@Component({
	selector: 'org-name',
	templateUrl: './org-name.component.html',
	styleUrls: ['./org-name.component.scss']
})
export class OrgNameComponent implements OnInit, OnChanges {

	@Input()
	id: string | number | null = null;

	@Input()
	name!: OrgName;

	@Input()
	downText: any;

	@Input()
	prefer: OrgNameType | null = null;

	@Input()
	class: string = '';

	@Input()
	bundleId: boolean = false;

	@Input()
	bundleShort: boolean = false;

	@Input()
	showTooltip: boolean = false;

	@Input()
	showDownText: boolean = false;

	displayName: string = '';
	otherName: OrgNameType = 'legal';

	constructor(
		public utilsService: UtilsService
	) {
	}

	ngOnInit(): void {
		// Initialize name types
		this.initNameTypes();

		// Get initial display name
		this.getDisplayName();
	}

	ngOnChanges(changes: SimpleChanges) {
		// Update display names on value changes
		if (changes['prefer']) {
			this.initNameTypes();
		}

		if (isDefined(changes['name']))
			this.getDisplayName();
	}

	initNameTypes() {
		// Get preferred name type if not provided
		if (!isDefined(this.prefer))
			this.prefer = this.utilsService.getOrgNameDisplay_Config();

		// Get complementary name type
		this.otherName = this.prefer === 'full' ? 'legal' : 'full';
	}

	getDisplayName(): void {
		// Preferred
		if (this.prefer !== null && isDefined(this.name[this.prefer])) {
			this.displayName = this.name[this.prefer] as string;
		}

		// Short
		else if (isDefined(this.name.short)) {
			this.displayName = this.name.short as string;
		}

		// Full
		else if (isDefined(this.name.full)) {
			this.displayName = this.name.full as string;
		}

		// Legal
		else if (isDefined(this.name.legal)) {
			this.displayName = this.name.legal as string;
		}

		// Fallback
		else {
			this.displayName = 'N/A';
		}
	}

	protected readonly isDefined = isDefined;
}
