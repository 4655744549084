<mat-form-field appearance="outline">
	<mat-chip-list #chipList aria-label="Tags selection">
		<mat-chip [ngStyle]="{color: option.dontExist ? 'red' : ''}" *ngFor="let option of tags" (removed)="remove(option)" [attr.aria-label]="'Selected element, to remove use Backspace Key'">
			{{ option.label[lang] || option.label['en'] }}
			<button id="btn_delete_tag_{{question.key}}{{getIndex(index)}}_{{option.value}}" [attr.aria-label]="'Enter to remove element ' + (option.label[lang] || option.label['en'])" [disabled]="disabled || readonly || question?.readOnly || question?.disabled || false" matChipRemove>
				<mat-icon>cancel</mat-icon>
			</button>
		</mat-chip>

		<input
			[id]="question.key+getIndex(index)"
			#tagInput
			[placeholder]="question.placeholder[lang] || question.placeholder['en'] || 'Start typing here...'"
			[formControl]="tagCtrl"
			[disabled]="disabled || readonly || question?.readOnly || question?.disabled || false"
			[matAutocomplete]="auto"
			[matChipInputFor]="chipList"
			[matChipInputSeparatorKeyCodes]="separatorKeysCodes"
			(matChipInputTokenEnd)="add($event)"
			autocomplete="zip-code"
			[attr.aria-label]="'Please start typing or use keys to move through options, enter when you want to select an option.' + (question.singleValue ? 'You can only select one option' : 'You can select multiple options.') + 'To remove elements use backspace key.'"
		/>
	</mat-chip-list>

	<mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
		<mat-option id="{{question.key}}{{getIndex(index)}}_{{tag.value}}" *ngFor="let tag of filteredTags" [value]="tag">
			{{ tag.label[lang] || tag.label['en'] }}
		</mat-option>
	</mat-autocomplete>
</mat-form-field>
