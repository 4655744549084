<div *ngIf="!readonly">
	<ngx-file-drop *ngIf="definition.multipleFiles || (!definition.multipleFiles && this.fileData.length < 1)" [dropZoneClassName]="dropFile ? 'fileOver' : 'fileDrop'" contentClassName="fileContent" dropZoneLabel="Drop files here"
			(onFileDrop)="dropped($event)" (onFileOver)="fileOver($event)" (onFileLeave)="fileLeave($event)" [multiple]="definition?.multipleFiles" [accept]="definition?.extensionsAllowed || defaultExtensionsAllowed" [id]="'input_file_'+definition.key+getIndex(indexElement)">
		<ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
			<div class="p-4" (click)="openFileSelector()" (keyup.enter)="openFileSelector()" style="cursor: pointer" tabindex="0">
				<i class="fa fa-cloud-upload"></i><br>
				Drag and drop file(s) or click here
			</div>
		</ng-template>
	</ngx-file-drop>

	<table mat-table [dataSource]="dataSource" class="mb-4">
		<!-- Position Column -->
		<ng-container matColumnDef="name">
			<th mat-header-cell *matHeaderCellDef></th>
			<td mat-cell *matCellDef="let element; let index = index;">
				<a id="download_file_{{fileKey}}{{getIndex(indexElement)}}_{{index}}" style="cursor: pointer"
					(click)="downloadFile(this.submissionId, this.fileKey, index, element, element.substring(element.lastIndexOf('.')+1))">
					{{element}}
				</a>
			</td>
		</ng-container>

		<!-- Name Column -->
		<ng-container matColumnDef="action">
			<th mat-header-cell *matHeaderCellDef></th>
			<td mat-cell *matCellDef="let element; let index = index;">
				<button id="delete_file_{{fileKey}}{{getIndex(indexElement)}}_{{index}}" type="button" title="Delete file" class="btn btn-outline-danger"
					(click)="deleteFile(this.submissionId, this.fileKey, index)" *ngIf="delete">
					<i class="fa fa-fw fa-trash"></i>
				</button>
			</td>
		</ng-container>

		<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
		<tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
	</table>
</div>

<div *ngIf="readonly">
	<mat-list role="list" *ngFor="let file of fileData; let index = index;">
		<mat-list-item role="listitem" *ngIf="file">
			<a id="download_file_{{fileKey}}{{getIndex(indexElement)}}_{{index}}" style="cursor: pointer"
				(click)="downloadFile(this.submissionId, this.fileKey, index, file, file.substring(file.lastIndexOf('.')+1))">
				{{file}}
			</a>
		</mat-list-item>
	</mat-list>
	<p *ngIf="fileData.length <= 0">
		<i>
			{{ readonly ? 'No files uploaded' : 'Pending Upload' }}
		</i>
	</p>
</div>
