export const environment = {
	production: false,
	baseService: 'https://dev-01.cord.osaas.app',
	services: {
		cord: '/api/v1',
	},
	authConfig: {
		environmentId: 'db61cbfb-24fc-45f3-b5c5-6e9a14022ee2',
		clientId: '8cc8337b-ed3f-4171-a7d1-038869ad0297',
		authUri: 'https://dev-auth.cord.osaas.app',
		redirectUrl: '/portal/',
		scopes: 'openid profile email',
	},
	mapboxglKey: 'pk.eyJ1Ijoib3NhYXNsbGMiLCJhIjoiY2xkeHVoNmFoMGtzMzN2b2phY2d2M2VkZCJ9.WbNzEzXRuzJfl0LEQm7syA',
	encryptKey: 'HuNSFAdSxX5X80hJZHdsMBlNRzjdRl9R',
	feedback: {
		enabled: true,
		cta: {"en": "Feedback", "es": "Comentarios"},
		title: {"en": "Feedback", "es": "Comentarios"},
		baseFeedbackUrl: 'https://forms.clickup.com/2205076/f/239cm-4100/LIH4EUXAKMZ9UEZDOV',
	},
	recaptcha: {
	 	siteKey: '6LeYCEUpAAAAAJ99ysARcO0yFzr2hTAFa05pnPrZ',
    }
};
