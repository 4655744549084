import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import isDefined from 'src/app/core/utils/isDefined';
import { BaseFormElement, questionDynamicCatalog } from '../../models/question-model';
import * as _ from 'lodash';

@Component({
	selector: 'repeated-content',
	templateUrl: './repeated-content.component.html',
	styleUrls: ['./repeated-content.component.scss']
})
export class RepeatedContentComponent implements OnInit {

	@Input() question: questionDynamicCatalog = {} as questionDynamicCatalog;
	@Input() lang: string = 'en';
	@Input() readonly: boolean = false;
	@Input() disabled: boolean = false;
	@Input() payload: any = {} as any;
	@Input() extraData: any = {} as any;
	@Input() generalDefinition: any = [] as any;

	@Output() valid: EventEmitter<boolean> = new EventEmitter();
	@Output() changeEvent: EventEmitter<any> = new EventEmitter();

	definition: any = [];
	values: any = [];
	referenceValue: any = null;

	payloadElements: any = null;

	repeatedType: 'numeric' | 'array' | null = null;

	previousPayload: any = {};
	previousFinalValue: any;

	validations: any = {};

	// showField$: Observable<boolean>;

	constructor(private cdRef: ChangeDetectorRef) {
	}

	ngOnInit(): void {
		this.cdRef.detectChanges(); // Fuerza la detección de cambios
		// this.showField$ = this.fulfillsCondition()
	}

	ngOnChanges(changes: SimpleChanges) {
		this.definition = isDefined(this.question.content) ? JSON.parse(JSON.stringify(this.question.content)) : [];

		if ('payload' in changes && this.previousPayload !== JSON.stringify(changes['payload'].currentValue)) {
			this.previousPayload = JSON.stringify(changes['payload'].currentValue);

			this.referenceValue = this.payload[this.question.reference] || null;

			this.getColum(this.question, 'element_colspan', 'element_colClass');

			this.values = [];
			const valueFromPayload = this.payload[this.question.key];

			if (typeof this.referenceValue === 'object' && this.referenceValue !== null) {
				this.repeatedType = 'array';

				this.values = this.referenceValue.map((value: any) => {
					let definitionTemp: any = [];
					this.definition?.forEach((field: any) => {
						const fieldTemp = JSON.parse(JSON.stringify(field));
						if ('key' in fieldTemp) {
							fieldTemp.value = isDefined(valueFromPayload) && value in valueFromPayload && isDefined(valueFromPayload[value]) && fieldTemp.key in valueFromPayload[value] ? valueFromPayload[value][fieldTemp.key] : null;
							fieldTemp.valid = isDefined(this.validations[value]) && fieldTemp.key in this.validations[value] ? this.validations[value][fieldTemp.key] : false;
						}

						this.getColum(fieldTemp);

						definitionTemp.push(fieldTemp);
					});

					return {
						index: value,
						label: this.getLabel(value),
						payload: {
							...(isDefined(valueFromPayload) && value in valueFromPayload ? valueFromPayload[value] : {})
						},
						definition: JSON.parse(JSON.stringify(definitionTemp))
					};
				});
			} else if (typeof this.referenceValue === 'number') {
				this.repeatedType = 'numeric';

				for (let i = 0; this.referenceValue > i; i++) {
					let definitionTemp: any = [];

					this.definition?.forEach((field: any) => {
						const fieldTemp = JSON.parse(JSON.stringify(field));
						if ('key' in fieldTemp) {
							fieldTemp.value = isDefined(valueFromPayload) && isDefined(valueFromPayload[i]) && fieldTemp.key in valueFromPayload[i] ? valueFromPayload[i][fieldTemp.key] : null;
						}

						this.getColum(fieldTemp);

						definitionTemp.push(fieldTemp);
					});

					this.values.push(Object.assign({}, {
						index: i,
						payload: {
							...(isDefined(valueFromPayload) && valueFromPayload.length > 0 ? valueFromPayload[i] || {} : {})
						},
						definition: JSON.parse(JSON.stringify(definitionTemp))
					}));
				}
			}

			this.previousFinalValue = this.payload[this.question.key] || (this.repeatedType === 'numeric' ? [] : {});
			this.payloadElements = this.question.value || (this.repeatedType === 'numeric' ? [] : {});
			// console.log("Type", this.repeatedType)
			// console.log("On change Repeated", this.question.value, this.payloadElements)

			//Process incoming payload changes
			this.dataChanged({}, null);
		}
	}

	dataChanged(change: any, keyItem: any) {
		let finalValue: any;

		finalValue = this.repeatedType === 'numeric' ? [] : {};
		this.values.forEach((value: any) => {
			if (value.index === keyItem) {
				finalValue[value.index] = {
					...value.payload,
					...finalValue[value.index],
					...{[change.key]: change.value}
				};
			} else if (isDefined(this.payloadElements[value.index])) {
				finalValue[value.index] = this.payloadElements[value.index];
			} else {
				finalValue[value.index] = {};
			}
		});

		if (!_.isEqual(this.previousFinalValue, finalValue)) {
			// this.previousFinalValue = _.cloneDeep(finalValue);
			this.question.value = finalValue;
			this.changeEvent.emit({value: finalValue, key: this.question.key});
		}
	}

	getColum(field: any, colspan: 'colspan' | 'element_colspan' = 'colspan', colClass: 'colClass' | 'element_colClass' = 'colClass') {
		if (field[colspan]) {
			const extraClass = field[colClass] || '';
			field[colClass] = '';

			field[colspan].forEach((col: any, index: any) => {
				// Skip null values
				if (col === null || col === undefined) return;

				switch (index) {
					case 0: // XS
						field[colClass] += ' col-' + col;
						break;

					case 1: // SM
						field[colClass] += ' col-sm-' + col;
						break;

					case 2: // MD
						field[colClass] += ' col-md-' + col;
						break;

					case 3: // LG
						field[colClass] += ' col-lg-' + col;
						break;

					case 4: // XL
						field[colClass] += ' col-xl-' + col;
						break;

					case 5: // XXL
						field[colClass] += ' col-xxl-' + col;
						break;

				}
			});

			field[colClass] += ' ' + extraClass;
		} else {
			field[colClass] = null;
		}
	}

	getLabel(value: string) {
		const DEFINITION = this.generalDefinition || [];

		let referenceField: any = null;
		const referenceKey = this.question.reference;

		DEFINITION.find((step: any) => {
			return step.content.some((field: any) => {
				if (field.key === referenceKey)
					referenceField = field;
				return field.key === referenceKey;
			});
		});

		const optionSelected = referenceField?.options?.find((option: any) => {
			return option.value === value;
		});

		return isDefined(optionSelected) ? optionSelected.label || value : value;
	}

	getTranslation(textJson: any) {
		return isDefined(textJson) ?
			typeof textJson === 'object'
				? textJson[this.lang] || textJson['en'] || '-'
				: textJson || ' - '
			: ' - ';
	}

	validateAll() {
		let isValid = true;

		this.validations = {};

		this.values.forEach((content: any) => {
			this.validations[content.index] = {};
			content.definition.forEach((field: any) => {
				const isShown = this.fulfillsCondition(field, content.payload, content.index);
				this.validations[content.index][field.key] = isShown ? field.valid : true;
				if (isShown && !field.valid) isValid = false;
			});

		});
		return isValid;
	}

	processValid(validChange: any, index: number, inner: number) {
		const content = this.values[index];
		const field = content.definition[inner];
		// debugger
		field.valid = validChange;
		this.question.completed = this.validateAll();
	}

	fulfillsCondition(element: BaseFormElement, innerPayload: any, elementIndex: any) {
		let conditionsType = element.conditionRulesLogicalOperator || 'AND';
		let correct = 0;

		if (element.conditional) {
			let data = this.payload || {};

			if (element.conditional && element.conditionRules && element.conditionRules.length > 0) {
				element.conditionRules.forEach(rule => {
					// Set context for the validation of the rule
					if (!!rule.innerReference) data = {'_reference': elementIndex, ...(innerPayload || {})};
					else data = this.payload || {};

					// Get field value and if not defined, fallback to extraData
					let fieldValue = data[rule.questionKey] !== undefined
						? data[rule.questionKey]
						: (
							this.extraData[rule.questionKey] !== undefined
								? this.extraData[rule.questionKey]
								: null
						);

					let criteriaValue = 'criteriaKey' in rule && rule.criteriaKey !== null && rule.criteriaKey !== undefined ? data[rule.criteriaKey] || this.extraData[rule.criteriaKey] : rule.criteria;

					// console.log(data, criteriaValue, fieldValue);
					// For array values, set value to array length (except for array operators)
					if (Array.isArray(fieldValue) && !['includes'].includes(rule.operator)) {
						fieldValue = fieldValue.length || 0;
					}

					switch (rule.operator) {
						case '=':
							if (fieldValue === criteriaValue)
								correct++;
							break;

						case '!=':
							if (fieldValue != criteriaValue)
								correct++;
							break;

						case '>':
							if (fieldValue > criteriaValue)
								correct++;
							break;

						case '>=':
							if (fieldValue >= criteriaValue)
								correct++;
							break;

						case '<':
							if (fieldValue < criteriaValue)
								correct++;
							break;

						case '<=':
							if (fieldValue <= criteriaValue)
								correct++;
							break;

						case 'empty':
							if (!isDefined(fieldValue))
								correct++;
							break;

						case 'not-empty':
							if (isDefined(fieldValue))
								correct++;
							break;

						case 'includes': // Array value includes a given reference value
							if (fieldValue?.includes(criteriaValue))
								correct++;
							break;

						case 'in': // Value is in a list of given reference values
							console.log(this.question.key, elementIndex, criteriaValue, fieldValue, data);
							if (criteriaValue?.includes(fieldValue))
								correct++;
							break;
					}
				});
			}
			return conditionsType == 'AND' ? element?.conditionRules?.length == correct : correct > 0;
			// return of(conditionsType == 'AND' ? element?.conditionRules?.length == correct : correct > 0);
		} else {
			return true;
			// return of(true);
		}
	}

}
