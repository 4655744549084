import { Component, HostBinding, OnInit } from '@angular/core';
import posthog from 'posthog-js';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
	selector: 'app-landing-page',
	templateUrl: './landing-page.component.html',
	styleUrls: ['./landing-page.component.scss']
})
export class LandingPageComponent implements OnInit {
	@HostBinding('class.feedback-open') isFeedbackWidgetOpen: boolean = false;

	onboardingEnabled: boolean = false;
	redirectURL: string = '/';

	constructor(
		private router: Router,
		private activatedRoute: ActivatedRoute
	) {
	}

	ngOnInit(): void {
		this.activatedRoute.queryParams.subscribe((queryParams) => {
			this.redirectURL = '';
			if ('go_to' in queryParams) {
				this.redirectURL = queryParams['go_to'] || '';
				if (Object.keys(queryParams).length > 1) {
					Object.keys(queryParams).forEach((key) => {
						if (key !== 'go_to')
							this.redirectURL += `&${key}=${queryParams[key]}`;
					});
				}
			}
		});
		//Doesn't work without Auth?
		this.onboardingEnabled = posthog.isFeatureEnabled('user-registration-onboarding-flow', {send_event: true}) || false;
	}

	onFeedbackWidgetChange(isOpen: boolean) {
		this.isFeedbackWidgetOpen = isOpen;

		if (isOpen)
			document.body.classList.add('feedback-open');
		else
			document.body.classList.remove('feedback-open');


		this.onboardingEnabled = posthog.isFeatureEnabled('user-registration-onboarding-flow', {send_event: false}) || false;
		// console.log('Enabled?', this.onboardingEnabled);
	}

}
