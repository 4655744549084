<mat-form-field appearance="outline">
    <mat-select
        (selectionChange)="returnSelectedValue($event.value)"
        [disabled]="readonly || disabled || question.disabled || question.readOnly || false"
		id="{{ question.key }}{{getIndex(index)}}"
        [(ngModel)]="question.value"
    >
        <mat-option
            *ngFor="let opt of dynamicOptions"
            [value]="opt.value"
			id="option_{{question.key}}{{getIndex(index)}}_{{opt.value}}"
		>
            {{ opt.label }}
        </mat-option>
    </mat-select>
</mat-form-field>
