import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import getIndex, { BaseFormElement, Option } from '../../models/question-model';

export interface optionChecked extends Option {
	isChecked: boolean;
}

@Component({
	selector: 'multiple-checkbox',
	templateUrl: './multiple-checkbox.component.html',
	styleUrls: ['./multiple-checkbox.component.scss']
})
export class MultipleCheckboxComponent implements OnInit {

	@Input() payload: any = {};
	@Input() lang: string = 'en';
	@Input() question!: BaseFormElement;
	@Input() readonly: boolean = false;
	@Input() disabled: boolean = false;

	//For repeated inner elements
	@Input() index: number | null = null;

	@Output() changeEvent: EventEmitter<any> = new EventEmitter();

	valuesReturn: any[] = [];

	optionsChecks: optionChecked[] = [];

	constructor() {
	}

	ngOnInit(): void {
	}

	ngOnChanges(changes: SimpleChanges): void {
		//Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
		//Add '${implements OnChanges}' to the class.
		let key = this.question.key;
		this.valuesReturn = key in this.payload ? this.payload[key] : [];

		this.valuesReturn = this.valuesReturn ? this.valuesReturn : [];

		this.question.options = this.question.options || [];
		this.optionsChecks = this.question.options.map((element: any) => {
			element['isChecked'] = this.valuesReturn ? this.valuesReturn.includes(element.value) : false;
			return element;
		});
	}

	isChecked(event: any, optionIndex: number) {
		let option: optionChecked = this.optionsChecks[optionIndex];
		const MAX = this.question.maxLength || this.question.options?.length || 9999;
		const CHECKED = !!event.target.checked;
		if (CHECKED && !this.valuesReturn.includes(option.value)) {
			//Checked if checked values are valid
			if (MAX > this.valuesReturn.length) {
				//Add value to valueResult
				this.valuesReturn.push(option.value);
			} else {
				//Doesn't check
				event.preventDefault();
			}
		} else if (!CHECKED && this.valuesReturn.includes(option.value)) {
			//Delete element from valueResult
			const indexValue = this.valuesReturn.indexOf(option.value);
			if (indexValue >= 0) this.valuesReturn.splice(indexValue, 1);
		}

		//Update/Emit checked values
		this.changeEvent.emit(this.valuesReturn);
	}

	protected readonly getIndex = getIndex;
}
