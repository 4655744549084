import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { map, Observable } from 'rxjs';
import { FormControl } from '@angular/forms';
import { startWith } from 'rxjs/operators';
import getIndex, { questionDynamicCatalog } from '../dynamic-form/models/question-model';
import isDefined from '../../../core/utils/isDefined';
import { UtilsService } from '../../../services/utils.service';
import { NtwMgmtService } from '../../../modules/networks/ntw-mgmt.service';
import { OrgNameType } from '../../../core/models/System.model';

@Component({
	selector: 'org-search',
	templateUrl: './org-search.component.html',
	styleUrls: ['./org-search.component.scss']
})
export class OrgSearchComponent implements OnInit {
	@Input() question: questionDynamicCatalog = {} as questionDynamicCatalog;
	@Input() lang: string = 'en';
	@Input() readonly: boolean = false;
	@Input() disabled: boolean = false;
	@Input() payload: any = {} as any;
	@Input() extraData: any = {} as any;

	//For repeated inner elements
	@Input() index: number | null = null;

	@Output() valid: EventEmitter<boolean> = new EventEmitter();
	@Output() changeEvent: EventEmitter<any> = new EventEmitter();


	options: any[] = [];
	filteredOptions!: Observable<any[]>;

	orgSelected = new FormControl('');

	networkId: any = null;

	orgDisplayName: OrgNameType = 'full';

	constructor(
		// private userMgmtServiceService: UserMgmtServiceService,
		private ntwMgmtService: NtwMgmtService,
		public utilsService: UtilsService
	) {
	}

	ngOnInit() {
		this.orgDisplayName = this.utilsService.getOrgNameDisplay_Config();
	}

	ngOnChanges(changes: SimpleChanges): void {
		//Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
		//Add '${implements OnChanges}' to the class.


		if (this.payload[this.question.key] !== undefined) {
			const org = this.options.find(org => org.id === this.payload[this.question.key]);

			if (isDefined(org))
				this.orgSelected.setValue(this.utilsService.getSelectedOrgNameDisplay(org.name));
		}

		const ntwKey = this.question.orgSelectorConfig?.networkIdKey || '';

		if ('question' in changes) {
			if (isDefined(this.extraData[ntwKey])) {
				this.networkId = this.extraData[ntwKey];
				this.disabled = false;
				this.readonly = false;
				this.getOrganizations();
			} else {
				this.disabled = true;
				this.readonly = true;
			}
		}

	}

	private _filter(value: string) {
		const filterValue = value.toLowerCase();

		return this.options.filter(option => option.id.toString().includes(filterValue)
			|| option.name.short?.toLowerCase().includes(filterValue)
			|| option.name.full?.toLowerCase().includes(filterValue)
			|| option.name.legal?.toLowerCase().includes(filterValue)
		);
	}

	getOrganizations() {
		this.ntwMgmtService.listNetworkMembers(this.networkId).subscribe(({success, data}) => {
			if (success && data !== null) {
				this.options = data.map((r: Record<string, any>) => ({
					name: isDefined(r['orgName']) ? r['orgName'] : r['name'],
					id: isDefined(r['orgId']) ? r['orgId'] : r['id']
				}));

				this.filteredOptions = this.orgSelected.valueChanges.pipe(
					startWith(''),
					map((value: any) => {
						if (value === null)
							this.changeEvent.emit(null);

						if (typeof value === 'object' && value !== null) {
							this.changeEvent.emit(isDefined(value.id) ? value.id : null);
							return this._filter('');
						}

						return this._filter(value || '');
					}),
				);

				if (this.question.autoSelectFirst && this.options.length > 0 && !isDefined(this.question.value)) {
					this.orgSelected.setValue(this.options[0]);
					this.changeEvent.emit(this.options[0].id);
				}
			}
		});


		//TODO: Get other lists of organizations

		// this.userMgmtServiceService.listOrganizations().subscribe((response) => {
		// 	if (response.success && response.data !== null) {
		// 		this.options = response.data
		// 			.map((org: Record<string, any>) => {
		// 				return {
		// 					name: org['name'],
		// 					id: org['id']
		// 				}
		// 			});
		//
		// 		this.filteredOptions = this.orgSelected.valueChanges.pipe(
		// 			startWith(''),
		// 			map(value => this._filter(value || '')),
		// 		);
		// 	}
		// });
	}

	autocompleteDisplay(org: any) {
		if (org === '' || org === undefined || org === null)
			return '';

		return typeof org === 'string'
			? org :
			org.name[this.orgDisplayName] || org.name[this.orgDisplayName != 'legal' ? 'legal' : 'full'] || org.name['short'] || null;
	}

	protected readonly isDefined = isDefined;
	protected readonly getIndex = getIndex;
}
