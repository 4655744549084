<header-no-auth></header-no-auth>

<div class="container-fluid px-5 my-3">
	<div class="row mb-3">
		<div class="col-12 col-sm-6 d-flex align-items-center justify-content-start">
			<h1 class="m-0" style="font-weight: bold">{{ 'onboarding.welcome' | translate }}</h1>
		</div>
	</div>

	<div class="row">
		<div class="col-sm-12">
			<mat-card class="my-2 p-5">
				<mat-card-content>
					<h2 class="m-0">Existing Account?</h2>
					<p>
						If you already have a CORD account with your email, you're ready to go.
					</p>
					<a [routerLink]="['/login']" [queryParams]="{go_to: redirectURL}" mat-raised-button> Sign In</a>

					<hr class="mt-4 mb-4">

					<h2 class="m-0">New Account</h2>
					<p>
						Create a new CORD account with your business email address.
					</p>
					<button [routerLink]="['/register']" mat-raised-button>Create Account</button>
				</mat-card-content>
			</mat-card>
		</div>
	</div>
</div>

<app-footer></app-footer>

<app-feedback (widgetToggle)="onFeedbackWidgetChange($event)"></app-feedback>
