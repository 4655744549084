import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { all, create } from 'mathjs';
import getIndex, { FormulaConfiguration, questionDynamicCatalog } from '../../models/question-model';
import isDefined from '../../../../../core/utils/isDefined';


@Component({
	selector: 'expression',
	templateUrl: './expression.component.html',
	styleUrls: ['./expression.component.scss']
})
export class ExpressionComponent implements OnInit {
	@Input() question: questionDynamicCatalog = {} as questionDynamicCatalog;
	@Input() lang: string = 'en';
	@Input() readonly: boolean = false;
	@Input() disabled: boolean = false;
	@Input() payload: any = {} as any;
	@Input() extraData: any = {} as any;
	@Input() formDefinition: any = [] as any;

	//For repeated inner elements
	@Input() index: number | null = null;

	@Output() valid: EventEmitter<boolean> = new EventEmitter();
	@Output() changeEvent: EventEmitter<any> = new EventEmitter();

	config = {};
	math = create(all, this.config);

	resultValue: any = 0;

	previousPayload: any = {};

	constructor() {
	}

	ngOnInit(): void {
		// Declare custom functions
		this.math.import({
			coalesce: (value: any, defaultValue: any) => value !== null && value !== undefined ? value : defaultValue,
			getKeyFromObjectArray: (array: any, key: any, defaultValue: any) => {
				if (isDefined(array)) {
					return Object.entries(array).map(([, value]) => {
						// @ts-ignore
						return (defaultValue !== undefined && (isDefined(value) && value[key] === undefined || value[key] === null)) ? defaultValue : value[key];
					});
				} else
					return 0;
			}
		});


	}

	ngOnChanges(changes: SimpleChanges) {
		if ('payload' in changes && this.previousPayload !== JSON.stringify(changes['payload'].currentValue)) {
			this.previousPayload = JSON.stringify(changes['payload'].currentValue);
			setTimeout(() => {
				this.resolveExpression();
			}, 50);
		}
	}

	resolveExpression() {
		this.resultValue = 0;
		const config: FormulaConfiguration = this.question.formula || {expression: '', valueDisplay: 'text', criteriaKeys: []};
		const data = this.payload || {};

		try {
			this.resultValue = this.math.evaluate(config.expression, {data});
		} catch (e) {
			this.resultValue = this.payload[this.question.key] || 0;
			return;
		}

		if (this.payload[this.question.key] !== this.resultValue)
			this.changeEvent.emit(Number(this.resultValue));
	}

	protected readonly getIndex = getIndex;
}
