<mat-accordion multi>
	<ng-container *ngFor="let accordion of data?.content; let index = index">
		<mat-expansion-panel>
			<mat-expansion-panel-header>
				<mat-panel-title>
					<h3 class="mb-0">{{ accordion?.title?.en}}</h3>
				</mat-panel-title>
				<mat-panel-description>
					{{ accordion?.description?.en}}
				</mat-panel-description>
			</mat-expansion-panel-header>
			<ng-container *ngFor="let content of accordion.content">
				<!--<app-container [data]="content"></app-container>-->
				<div [innerHTML]="content?.label?.en"></div>
			</ng-container>
		</mat-expansion-panel>
	</ng-container>
</mat-accordion>
