<!-- Field Header -->
<ng-template #fieldHeader>
	<label [for]="definition.key" class="{{ definition.controlType }}-label">
		<p class="field-label">
			<ng-container *ngIf="definition.label !== null">
				<span [innerHTML]="definition.label[lang] || definition.label['en'] || ''"></span>
			</ng-container>
			<span class="text-danger ms-1" *ngIf="definition.required">*</span>
			<button style="height: 0.5rem; line-height: 0.5rem;" mat-icon-button *ngIf="isDefined(definition.helpConfig?.tooltip || definition.helpConfig?.popup)" [ngStyle]="{cursor: isDefined(definition.helpConfig?.popup) ? 'pointer' : 'auto' }" [matTooltip]="getTranslation(definition.helpConfig?.tooltip || defaultTitleHelp)" [matTooltipPosition]="definition.helpConfig?.tooltipPosition || 'above'" (click)="openPopupHelp()">
				<i class="{{ definition.helpConfig?.icon || defaultIconHelp}}"></i>
			</button>
			<span class="sr-only" *ngIf="definition.required">(required field)</span>

		</p>
		<p class="field-description"
			*ngIf="definition.description !== undefined && definition.description !== null && (definition.description[lang] !== undefined || definition.description['en'] !== undefined)"
			[innerHTML]="definition.description[lang] || definition.description['en'] || ''"
		></p>
	</label>
</ng-template>

<ng-template #fieldHelp>
	<mat-hint class="{{ definition.controlType }}-hint" *ngIf="((definition.limitWords !== undefined && definition.limitWords !== null) || (definition.maxLength !== undefined && definition.maxLength !== null) || (definition.minWords !== undefined && definition.minWords !== null)) || (!isValid)">
		<span id="txt_invalid_{{definition.key}}{{getIndex()}}" class="text-danger" *ngIf="!isValid && !readonly && !disabled">
			{{ invalidReasons }}
		</span>

		<aside class="float-end" *ngIf="(definition.limitWords !== undefined && definition.limitWords !== null) || (definition.maxLength !== undefined && definition.maxLength !== null) || (definition.minWords !== undefined && definition.minWords !== null)">
			<span *ngIf="definition.limitWords !== undefined && definition.limitWords !== null" [ngClass]="{'text-danger fw-bold': totalWords >= definition.limitWords}">
				Words: {{ totalWords }}/{{ definition.limitWords }}
			</span>

			<span *ngIf="(definition.minWords !== undefined && definition.minWords !== null)" [ngClass]="{'text-danger fw-bold': totalWords < definition.minWords}">
				Minimum words: {{ totalWords }}/{{ definition.minWords }}
			</span>

			<span *ngIf="definition.maxLength !== undefined && definition.maxLength !== null && definition.controlType !== 'tags' && definition.controlType !== 'multiple-checkbox'">
				Total characters: {{ definition.value?.length || 0 }}/{{ definition.maxLength }}
			</span>
		</aside>
	</mat-hint>
</ng-template>

<ng-container [ngSwitch]="definition.controlType">

	<ng-container *ngSwitchCase="'textbox'">
		<ng-container [ngSwitch]="definition.type">
			<ng-container *ngSwitchCase="'percent'">
				<ng-container *ngTemplateOutlet="fieldHeader"></ng-container>

				<mat-form-field appearance="outline">
					<input
						(ngModelChange)="returnValue($event)"
						(change)="countWords(definition.value)"
						(keydown)="countWords(definition.value)"
						(keyup)="countWords(definition.value)"
						matInput
						[placeholder]="(definition.placeholder !== undefined && definition.placeholder !== null) ? (definition.placeholder[lang] || definition.placeholder['en']) : ''"
						[(ngModel)]="definition.value"
						[ngModelOptions]="{standalone: true}"
						[readonly]="readonly || definition?.readOnly || false"
						[disabled]="disabled || definition?.disabled || false"
						maxlength="{{ definition.maxLength || maxCharacters }}"
						id="{{ definition.key }}{{getIndex()}}"
						type="text"
						mask="percent"
						suffix="%"
						[ngClass]="{
							'is-invalid': !isValid
						}"
					/>
				</mat-form-field>

				<ng-container *ngTemplateOutlet="fieldHelp"></ng-container>
			</ng-container>

			<ng-container *ngSwitchCase="'currency'">
				<ng-container *ngTemplateOutlet="fieldHeader"></ng-container>

				<mat-form-field appearance="outline">
					<input
						(ngModelChange)="returnValue($event)"
						(change)="countWords(definition.value)"
						(keydown)="countWords(definition.value)"
						(keyup)="countWords(definition.value)"
						matInput
						[placeholder]="(definition.placeholder !== undefined && definition.placeholder !== null) ? (definition.placeholder[lang] || definition.placeholder['en']) : ''"
						[(ngModel)]="definition.value"
						[ngModelOptions]="{standalone: true}"
						[readonly]="readonly || definition?.readOnly || false"
						[disabled]="disabled || definition?.disabled || false"
						maxlength="{{ definition.maxLength || maxCharacters }}"
						id="{{ definition.key }}{{getIndex()}}"
						type="text"
						prefix="$ "
						mask="separator.2"
						thousandSeparator=","
						[ngClass]="{
							'is-invalid': !isValid
						}"
					/>
				</mat-form-field>

				<ng-container *ngTemplateOutlet="fieldHelp"></ng-container>
			</ng-container>

			<ng-container *ngSwitchCase="'email'">
				<ng-container *ngTemplateOutlet="fieldHeader"></ng-container>

				<mat-form-field appearance="outline">
					<input
						(change)="returnValue($event.target)"
						matInput
						[placeholder]="(definition.placeholder !== undefined && definition.placeholder !== null) ? (definition.placeholder[lang] || definition.placeholder['en']) : ''"
						[(ngModel)]="definition.value"
						[ngModelOptions]="{standalone: true}"
						[readonly]="readonly || definition?.readOnly || false"
						[disabled]="disabled || definition?.disabled || false"
						id="{{ definition.key }}{{getIndex()}}"
						[type]="'text'"
						[required]="definition?.required || false"
						[pattern]="emailPattern"
						[ngClass]="{
							'is-invalid': !isValid
						}"
					/>
				</mat-form-field>
				<ng-container *ngTemplateOutlet="fieldHelp"></ng-container>
			</ng-container>

			<ng-container *ngSwitchCase="'mask'">
				<ng-container *ngTemplateOutlet="fieldHeader"></ng-container>

				<mat-form-field appearance="outline">
					<input
						matInput
						id="{{ definition.key }}{{getIndex()}}"
						(ngModelChange)="returnValue($event)"
						(change)="countWords(definition.value)"
						(keydown)="countWords(definition.value)"
						(keyup)="countWords(definition.value)"
						[placeholder]="(definition.placeholder !== undefined && definition.placeholder !== null) ? (definition.placeholder[lang] || definition.placeholder['en']) : ''"
						[(ngModel)]="definition.value"
						[ngModelOptions]="{standalone: true}"
						[readonly]="readonly || definition?.readOnly || false"
						[disabled]="disabled || definition?.disabled || false"
						[type]="'text'"
						mask="{{ definition.mask || '' }}"
						[showMaskTyped]="true"
						[dropSpecialCharacters]="true"
						[required]="definition?.required || false"
						[ngClass]="{
							'is-invalid': !isValid
						}"
						maxlength="{{ definition.maxLength || maxCharacters }}"
					/>
				</mat-form-field>

				<ng-container *ngTemplateOutlet="fieldHelp"></ng-container>
			</ng-container>

			<ng-container *ngSwitchCase="'pattern'">
				<ng-container *ngTemplateOutlet="fieldHeader"></ng-container>

				<mat-form-field appearance="outline">
					<input
						(change)="returnValue($event.target)"
						matInput
						[placeholder]="(definition.placeholder !== undefined && definition.placeholder !== null) ? (definition.placeholder[lang] || definition.placeholder['en']) : ''"
						[(ngModel)]="definition.value"
						[ngModelOptions]="{standalone: true}"
						[readonly]="readonly || definition?.readOnly || false"
						[disabled]="disabled || definition?.disabled || false"
						id="{{ definition.key }}{{getIndex()}}"
						[type]="'text'"
						[required]="definition?.required || false"
						[pattern]="definition.pattern || defaultPattern"
						[ngClass]="{
							'is-invalid': !isPatterValid
						}"
					/>

				</mat-form-field>
				<ng-container *ngTemplateOutlet="fieldHelp"></ng-container>

				<!--{{definition.pattern}} ==> {{isPatterValid(definition.value)}}-->
			</ng-container>

			<ng-container *ngSwitchDefault>
				<ng-container *ngTemplateOutlet="fieldHeader"></ng-container>

				<mat-form-field appearance="outline">
					<input
						(change)="returnValue($event); countWords(definition.value)"
						(keydown)="countWords(definition.value)"
						(keyup)="countWords(definition.value)"
						matInput
						[placeholder]="(definition.placeholder !== undefined && definition.placeholder !== null) ? (definition.placeholder[lang] || definition.placeholder['en']) : ''"
						[(ngModel)]="definition.value"
						[ngModelOptions]="{standalone: true}"
						[readonly]="readonly || definition?.readOnly || false"
						[disabled]="disabled || definition?.disabled || false"
						id="{{ definition.key }}{{getIndex()}}"
						[type]="definition.type || 'text'"
						[required]="definition?.required || false"
						[ngClass]="{
							'is-invalid': !isValid
						}"
						maxlength="{{ definition.maxLength || maxCharacters }}"
					/>
				</mat-form-field>

				<ng-container *ngTemplateOutlet="fieldHelp"></ng-container>
			</ng-container>
		</ng-container>
	</ng-container>

	<ng-container *ngSwitchCase="'textarea'">
		<ng-container *ngTemplateOutlet="fieldHeader"></ng-container>

		<ng-container *ngIf="!readonly && !disabled && !definition.readOnly; else readOnlyTextarea">
			<mat-form-field appearance="outline" draggable="true">
			<textarea
				(change)="returnValue($event.target); countWords(definition.value)"
				(keydown)="countWords(definition.value)"
				(keyup)="countWords(definition.value)"
				[placeholder]="(definition.placeholder !== undefined && definition.placeholder !== null) ? (definition.placeholder[lang] || definition.placeholder['en'] || translations['typeHere'][lang] || translations['typeHere']['en']) : translations['typeHere'][lang] || translations['typeHere']['en']"
				matInput
				[(ngModel)]="definition.value"
				[ngModelOptions]="{standalone: true}"
				[readonly]="readonly || definition.readOnly || false"
				[disabled]="disabled || definition.disabled || false"
				id="{{ definition.key }}{{getIndex()}}"
				type="{{ definition.type }}"
				rows="4"
				maxlength="{{ definition.maxLength || maxCharacters }}"
				[ngClass]="{
					'is-invalid': !isValid
				}"
			></textarea>
			</mat-form-field>
		</ng-container>
		<ng-template #readOnlyTextarea>
			<div class="textarea-readonly">
				{{!!definition?.value ? definition.value : (definition.placeholder !== undefined && definition.placeholder !== null) ? (definition.placeholder[lang] || definition.placeholder['en'] || translations['typeHere'][lang] || translations['typeHere']["en"]) : translations['typeHere'][lang] || translations['typeHere']['en']}}
			</div>
		</ng-template>

		<ng-container *ngTemplateOutlet="fieldHelp"></ng-container>

		<!--		<div class="row mt-1">-->
		<!--			<sub class="col-12 text-end" *ngIf="question.maxLength">-->
		<!--				{{ question.value?.length || "0" }}/{{ question.maxLength }}-->
		<!--			</sub>-->
		<!--		</div>-->
	</ng-container>

	<ng-container *ngSwitchCase="'dropdown'">
		<ng-container *ngTemplateOutlet="fieldHeader"></ng-container>

		<mat-form-field appearance="outline">
			<mat-select
				(selectionChange)="returnValue($event.value)"
				[disabled]="readonly || disabled || definition.disabled || definition?.readOnly || false"
				id="{{ definition.key }}{{getIndex()}}"
				[(ngModel)]="definition.value"
				[ngModelOptions]="{standalone: true}"
				[ngClass]="{'is-invalid': !isValid}"
				[placeholder]="definition.placeholder[lang] || definition.placeholder['en'] || undefined"
			>
				<mat-option
					id="{{definition.key}}{{getIndex()}}_{{opt.value}}"
					*ngFor="let opt of definition.options"
					[value]="opt.value"
				>
					{{ opt.label[lang] || opt.label['en'] || "-" }}
				</mat-option>
			</mat-select>
		</mat-form-field>

		<ng-container *ngTemplateOutlet="fieldHelp"></ng-container>
	</ng-container>

	<ng-container *ngSwitchCase="'checkbox'">
		<div class="form-check">
			<ng-container *ngTemplateOutlet="fieldHeader"></ng-container>
			<input
				(change)="processCheckbox($event.target)"
				[(ngModel)]="definition.value"
				[ngModelOptions]="{standalone: true}"
				class="form-check-input"
				type="checkbox"
				id="{{ definition.key }}{{getIndex()}}"
				[disabled]="readonly || disabled || definition.disabled || definition.readOnly || false"
			/>
		</div>

		<ng-container *ngTemplateOutlet="fieldHelp"></ng-container>
	</ng-container>

	<ng-container *ngSwitchCase="'radiobutton'">
		<ng-container *ngTemplateOutlet="fieldHeader"></ng-container>

		<div class="form-check form-check-inline" *ngFor="let opt of definition.options; let index = index">
			<input
				(change)="returnValue($event, definition.controlType)"
				[disabled]="readonly || disabled || definition.disabled || definition.readOnly || false"
				class="form-check-input"
				[(ngModel)]="definition.value"
				[ngModelOptions]="{standalone: true}"
				[value]="opt.value"
				type="radio"
				name="{{ definition.key }}"
				id="{{ definition.key }}{{getIndex()}}_{{ index }}"
				[ngClass]="{'is-invalid': !isValid}"
			/>
			<label class="radio-option-label" for="{{ definition.key }}_{{ index }}" [innerHTML]="opt.label[lang] || opt.label['en']"></label>
		</div>

		<br />

		<ng-container *ngTemplateOutlet="fieldHelp"></ng-container>
	</ng-container>

	<ng-container *ngSwitchCase="'file'">
		<ng-container *ngTemplateOutlet="fieldHeader"></ng-container>

		<file [indexElement]="index" (filesOutput)="returnValue($event)" [fileKey]="definition?.key || ''" [submissionId]="extraData?.id" [readonly]="readonly || disabled || definition?.readOnly || definition.disabled || false" [definition]="definition" [module]="extraData?.moduleFile" [isAdmin]="isAdmin"></file>

		<ng-container *ngTemplateOutlet="fieldHelp"></ng-container>
	</ng-container>

	<ng-container *ngSwitchCase="'html'">
		<div *ngIf="definition.label" [innerHtml]="definition.label[lang] || definition.label['en'] || ''"></div>
	</ng-container>

	<div *ngSwitchCase="'container'">
		<app-container [data]="definition"></app-container>
	</div>

	<ng-container *ngSwitchCase="'valueText'">
		<ng-container [ngSwitch]="definition.style">
			<ng-container *ngSwitchCase="'bold'">
				<b>
					{{ definition.value || "-" }}
				</b>
			</ng-container>
			<ng-container *ngSwitchCase="'hide'">
			</ng-container>
			<ng-container *ngSwitchDefault>
				{{ definition.value || "-" }}
			</ng-container>
		</ng-container>
	</ng-container>

	<ng-container *ngSwitchCase="'multiple-checkbox'">
		<ng-container *ngTemplateOutlet="fieldHeader"></ng-container>

		<multiple-checkbox
			[index]="index"
			(changeEvent)="returnValue($event)"
			[lang]="lang"
			[question]="definition"
			[readonly]="readonly || definition?.readOnly || false"
			[disabled]="disabled || definition?.disabled || false"
			[payload]="payload"></multiple-checkbox>

		<br>
		<ng-container *ngTemplateOutlet="fieldHelp"></ng-container>
	</ng-container>

	<!-- <ng-container *ngSwitchCase="'multiple-input'" >
		<multiple-input></multiple-input>
	</ng-container>	 -->

	<ng-container *ngSwitchCase="'dynamic-catalog'">
		<ng-container *ngTemplateOutlet="fieldHeader"></ng-container>
		<dynamic-catalog
			[index]="index"
			(changeEvent)="returnValue($event)"
			[lang]="lang"
			[question]="definition"
			[readonly]="readonly || definition?.readOnly || false"
			[disabled]="disabled || !!definition?.disabled || false"
			[payload]="payload"></dynamic-catalog>
		<ng-container *ngTemplateOutlet="fieldHelp"></ng-container>
	</ng-container>

	<ng-container *ngSwitchCase="'tags'">
		<ng-container *ngTemplateOutlet="fieldHeader"></ng-container>

		<add-tags
			[index]="index"
			(changeEvent)="returnValue($event)"
			[lang]="lang"
			[question]="definition"
			[readonly]="readonly || !!definition?.readOnly || false"
			[disabled]="disabled || !!definition?.disabled || false"
			[payload]="payload"></add-tags>
		<br>
		<ng-container *ngTemplateOutlet="fieldHelp"></ng-container>
	</ng-container>

	<ng-container *ngSwitchCase="'formulaValue'">
		<ng-container *ngTemplateOutlet="fieldHeader"></ng-container>
		<formula
			(changeEvent)="returnValue($event)"
			[lang]="lang"
			[question]="definition"
			[readonly]="readonly || definition?.readOnly || false"
			[disabled]="disabled || !!definition?.disabled || false"
			[payload]="payload"
			[definition]="formDefinition"
		></formula>
		<br>
		<ng-container *ngTemplateOutlet="fieldHelp"></ng-container>
	</ng-container>

	<ng-container *ngSwitchCase="'orgSelector'">
		<ng-container *ngTemplateOutlet="fieldHeader"></ng-container>
		<org-search
			[index]="index"
			(changeEvent)="returnValue($event)"
			[lang]="lang"
			[question]="definition"
			[readonly]="readonly || definition?.readOnly || false"
			[disabled]="disabled || definition?.disabled || false"
			[extraData]="extraData"
			[payload]="payload"></org-search>
		<br>
		<ng-container *ngTemplateOutlet="fieldHelp"></ng-container>
	</ng-container>

	<ng-container *ngSwitchCase="'repeated-content'">
		<ng-container *ngTemplateOutlet="fieldHeader"></ng-container>
		<ng-container *ngTemplateOutlet="fieldHelp"></ng-container>
		<repeated-content
			(changeEvent)="returnValue($event)"
			[lang]="lang"
			[question]="definition"
			[readonly]="readonly || definition?.readOnly || false"
			[disabled]="disabled || !!definition?.disabled || false"
			[extraData]="extraData"
			[generalDefinition]="formDefinition"
			[payload]="payload"></repeated-content>
		<br>
	</ng-container>

	<ng-container *ngSwitchCase="'formula'">
		<ng-container *ngTemplateOutlet="fieldHeader"></ng-container>
		<expression
			[index]="index"
			(changeEvent)="returnValue($event)"
			[lang]="lang"
			[question]="definition"
			[readonly]="readonly || definition?.readOnly || false"
			[disabled]="disabled || definition?.disabled || false"
			[extraData]="extraData"
			[payload]="payload"
		></expression>
		<ng-container *ngTemplateOutlet="fieldHelp"></ng-container>
	</ng-container>
</ng-container>


<ng-template #helpDialog>
	<h2>{{getTranslation(definition?.helpConfig?.title || defaultTitleHelp)}}</h2>
	<mat-dialog-content>
		<div [innerHTML]="getTranslation(definition?.helpConfig?.popup)"></div>
	</mat-dialog-content>
</ng-template>
