import { Component, HostBinding, OnInit } from '@angular/core';
import { MENU_ITEMS } from '../constants/MenuItems';

@Component({
	selector: 'app-content',
	templateUrl: './layout.component.html',
	styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent implements OnInit {
	menu_items = MENU_ITEMS;

	@HostBinding('class.feedback-open') isFeedbackWidgetOpen: boolean = false;

	constructor() {
	}

	ngOnInit(): void {
		//
	}

	onFeedbackWidgetChange(isOpen: boolean) {
		this.isFeedbackWidgetOpen = isOpen;

		if (isOpen)
			document.body.classList.add('feedback-open');
		else
			document.body.classList.remove('feedback-open');
	}
}
