import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ApiResponse } from '../../core/models/ApiResponse';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
	providedIn: 'root'
})
export class NtwMgmtService {
	baseURL: string = `${environment.baseService}${environment.services.cord}`;

	response: ApiResponse = {
		message: 'ok',
		success: true,
		data: null
	};

	constructor(
		private http: HttpClient
	) {
	}

	getLanguages(networkId: number): Observable<ApiResponse> {
		return this.http.get<ApiResponse>(`${this.baseURL}/networks/${networkId}/languages`);
		// return this.http.get<ApiResponse>(`${this.baseURL}/i18n/apps/${appKey}/languages`);
	}

	getActivityTypes(orgId: number): Observable<ApiResponse> {
		this.response.data = [
			{
				id: 1,
				name: {'en': 'Door-to-door canvassing'}
			},
			{
				id: 2,
				name: {'en': 'Community canvassing'}
			},
			{
				id: 3,
				name: {'en': 'Trainings'}
			},
		];
		// return of(this.response)
		return this.http.get<ApiResponse>(`${this.baseURL}/orgs/${orgId}/activities`);
	}

	getNetworkDetails(networkId: number): Observable<ApiResponse> {
		return this.http.get<ApiResponse>(`${this.baseURL}/admin/networks/${networkId}`);
	}

	getNetworkConfig(networkId: number): Observable<ApiResponse> {
		return this.http.get<ApiResponse>(`${this.baseURL}/networks/${networkId}/config`);
	}

	getNetworkSubmissions(params?: any): Observable<ApiResponse> {
		return this.http.get<ApiResponse>(`${this.baseURL}/admin/networks/submissions`, {params: this.setParams(params)});
	}

	updateNetworkMember(networkId: number, memberId: number, dto: any): Observable<ApiResponse> {
		console.log('Updating...');
		return this.http.patch<ApiResponse>(`${this.baseURL}/networks/${networkId}/members/${memberId}`, dto);
	}

	updateNtwMemberData(networkId: number, memberId: number, dto: any): Observable<ApiResponse> {
		console.log('Updating Member...');

		return this.http.patch<ApiResponse>(`${this.baseURL}/networks/${networkId}/members/${memberId}`, dto);
	}

	//OWNER
	createNetwork(dto: any): Observable<ApiResponse> {
		return of(this.response);
	}

	updateNetwork(networkID: number, dto: any): Observable<ApiResponse> {
		return of(this.response);
	}

	setParams(params: any) {
		let queryParams = new HttpParams();
		if (params != null || params != undefined) {
			Object.keys(params).forEach((keyParam) => {
				if (params[keyParam] != null || params[keyParam] != undefined)
					queryParams = queryParams.append(keyParam, params[keyParam]);
			});
		}
		return queryParams;
	}

	//GOALS

	getListOfGoals(networkId: number, memberId: number): Observable<ApiResponse> {
		return this.http.get<ApiResponse>(`${this.baseURL}/networks/${networkId}/members/${memberId}/goals`);
	}

	createGoal(networkId: number, memberId: number, dto: any): Observable<ApiResponse> {
		// return of(this.response)
		return this.http.post<ApiResponse>(`${this.baseURL}/networks/${networkId}/members/${memberId}/goals`, dto);

	}

	updateGoals(networkId: number, memberId: number, goalIDs: number[], dto: any): Observable<ApiResponse> {
		// return of(this.response)
		let goalIds = goalIDs.join(',');
		return this.http.patch<ApiResponse>(`${this.baseURL}/networks/${networkId}/members/${memberId}/goals/${goalIds}`, dto);
	}

	// goalIDs = [1, 3]
	deleteGoals(networkId: number, memberId: number, goalIDs: number[]): Observable<ApiResponse> {
		let goalIds = goalIDs.join(',');
		return this.http.delete<ApiResponse>(`${this.baseURL}/networks/${networkId}/members/${memberId}/goals/${goalIds}`);
	}

	listNetworkMembers(networkId: number): Observable<ApiResponse> {
		return this.http.get<ApiResponse>(`${this.baseURL}/networks/${networkId}/members`);
	}

	getGoalPeriods(networkId: number): Observable<ApiResponse> {
		return this.http.get<ApiResponse>(`${this.baseURL}/networks/${networkId}/goalPeriods`);
	}

	// DELEGATES

	getDelegatesByNetwork(networkId: number, memberId: number): Observable<ApiResponse> {
		return this.http.get<ApiResponse>(`${this.baseURL}/networks/${networkId}/members/${memberId}/delegates`);
	}

	createDelegate(networkId: number, memberId: number, dto: any): Observable<ApiResponse> {
		return this.http.post<ApiResponse>(`${this.baseURL}/networks/${networkId}/members/${memberId}/delegates`, dto);
	}

	updateDelegate(networkId: number, memberId: number, delegateId: number, dto: any): Observable<ApiResponse> {
		return this.http.patch<ApiResponse>(`${this.baseURL}/networks/${networkId}/members/${memberId}/delegates/${delegateId}`, dto);
	}

	getDelegateDetails(networkId: number, memberId: number, delegateId: number): Observable<ApiResponse> {
		return this.http.get<ApiResponse>(`${this.baseURL}/networks/${networkId}/members/${memberId}/delegates/${delegateId}`);
	}

	deleteDelegates(networkId: number, memberId: number, delegateIDs: number[]): Observable<ApiResponse> {
		let delegateIds = delegateIDs.join(',');
		return this.http.delete<ApiResponse>(`${this.baseURL}/networks/${networkId}/members/${memberId}/delegates/${delegateIds}`);
	}

	//ORGANIZATIONS

	getOrganizationsList(nameOrg: string): Observable<ApiResponse> {
		return this.http.get<ApiResponse>(`${this.baseURL}/orgs/search/${nameOrg}`);
	}

	createOrganization(dto: any): Observable<ApiResponse> {
		return this.http.post<ApiResponse>(`${this.baseURL}/orgs`, dto);
	}

	createContactOrganization(orgId: number, dto: any): Observable<ApiResponse> {
		return this.http.post<ApiResponse>(`${this.baseURL}/orgs/${orgId}/contacts`, dto);
	}

	getOrganizationDetails(orgId: number): Observable<ApiResponse> {
		return this.http.get<ApiResponse>(`${this.baseURL}/orgs/${orgId}`);
	}

	getOrganizationContacts(orgId: number): Observable<ApiResponse> {
		return this.http.get<ApiResponse>(`${this.baseURL}/orgs/${orgId}/contacts`);
	}

	updateOrganizationDetails(orgId: number, dto: any): Observable<ApiResponse> {
		return this.http.patch<ApiResponse>(`${this.baseURL}/orgs/${orgId}`, dto);
	}

	updateContactDetails(orgId: number, contactId: number, dto: any): Observable<ApiResponse> {
		return this.http.patch<ApiResponse>(`${this.baseURL}/orgs/${orgId}/contacts/${contactId}`, dto);
	}

	getOrganizationClasses(): Observable<ApiResponse> {
		return this.http.get<ApiResponse>(`${this.baseURL}/orgs/classes`);
	}

	//ATTACHMENTS

	getAttachment(networkId: number, memberId: number, attachmentId: number, index: number): Observable<any> {
		const httpOptions = {responseType: 'blob' as 'json'};
		return this.http.get<ApiResponse>(`${this.baseURL}/networks/${networkId}/members/${memberId}/attachments/${attachmentId}/files/${index}`, httpOptions);
	}

	getAttachmentUrl(networkId: number, memberId: number, attachmentId: number, index: number): Observable<ApiResponse> {
		return this.http.get<ApiResponse>(`${this.baseURL}/networks/${networkId}/members/${memberId}/attachments/${attachmentId}/files/${index}/fileUrl`);
	}

	createAttachmentVyNetworkMember(networkId: number, memberId: number, key: any, file: any): Observable<ApiResponse> {
		const formData = new FormData();
		formData.append('file', file);
		return this.http.post<ApiResponse>(`${this.baseURL}/networks/${networkId}/members/${memberId}/attachments/${key}`, formData);
	}

	updateAttachmentFiles(networkId: number, memberId: number, attachmentId: number, file: any): Observable<ApiResponse> {
		const formData = new FormData();
		formData.append('file', file);
		return this.http.patch<ApiResponse>(`${this.baseURL}/networks/${networkId}/members/${memberId}/attachments/${attachmentId}/file`, formData);
	}

	deleteAttachment(networkId: number, memberId: number, attachmentId: number): Observable<ApiResponse> {
		return this.http.delete<ApiResponse>(`${this.baseURL}/networks/${networkId}/members/${memberId}/attachments/${attachmentId}`);
	}

	deleteFileFromAnAttachment(networkId: number, memberId: number, attachmentId: number, index: number): Observable<ApiResponse> {
		return this.http.delete<ApiResponse>(`${this.baseURL}/networks/${networkId}/members/${memberId}/attachments/${attachmentId}/files/${index}`);
	}

	getAttachmentsMember(networkId: number, memberId: number): Observable<ApiResponse> {
		return this.http.get<ApiResponse>(`${this.baseURL}/networks/${networkId}/members/${memberId}/attachments`);
	}

	getChangeRequests(networkId: number, memberId: number, params?: any): Observable<ApiResponse> {
		return this.http.get<ApiResponse>(`${this.baseURL}/networks/${networkId}/members/${memberId}/changeRequests`, {params: this.setParams(params)});
	}

	createNewChangeRequest(networkId: number, memberId: number, dto: any) {
		return this.http.post<ApiResponse>(`${this.baseURL}/networks/${networkId}/members/${memberId}/changeRequests`, dto);
	}

	getChangeRequestByID(networkId: number, memberId: number, changeRequestId: number, dto: any): Observable<ApiResponse> {
		return this.http.get<ApiResponse>(`${this.baseURL}/networks/${networkId}/members/${memberId}/changeRequests/${changeRequestId}`);
	}

	updateChangeRequest(networkId: number, memberId: number, changeRequestId: number, dto: any): Observable<ApiResponse> {
		return this.http.put<ApiResponse>(`${this.baseURL}/networks/${networkId}/members/${memberId}/changeRequests/${changeRequestId}`, dto);
	}

	markAsCompletedChangeRequest(networkId: number, memberId: number, changeRequestId: number): Observable<ApiResponse> {
		return this.http.patch<ApiResponse>(`${this.baseURL}/networks/${networkId}/members/${memberId}/changeRequests/${changeRequestId}/complete`, {});
	}

	markAsIncompleteChangeRequest(networkId: number, memberId: number, changeRequestId: number): Observable<ApiResponse> {
		return this.http.patch<ApiResponse>(`${this.baseURL}/networks/${networkId}/members/${memberId}/changeRequests/${changeRequestId}/incomplete`, {});
	}

	deleteChangeRequest(networkId: number, memberId: number, changeRequestId: number) {
		return this.http.delete<ApiResponse>(`${this.baseURL}/networks/${networkId}/members/${memberId}/changeRequests/${changeRequestId}`);
	}

	getDistrictTypes(): Observable<ApiResponse> {
		return this.http.get<ApiResponse>(`${this.baseURL}/networks/districtTypes`);
	}

	getOrgCounty(networkId: number): Observable<ApiResponse> {
		return this.http.get<ApiResponse>(`${this.baseURL}/networks/${networkId}/counties`);
	}

	getOrgDistrict(networkId: number, typeId: number): Observable<ApiResponse> {
		return this.http.get<ApiResponse>(`${this.baseURL}/networks/${networkId}/districts/${typeId}`);
	}

	getNetworkMgmtDetails(networkId: number): Observable<ApiResponse> {
		return this.http.get<ApiResponse>(`${this.baseURL}/admin/networks/${networkId}`);
	}

	updateCustomDataMember(networkId: number, memberId: number, data: any): Observable<ApiResponse> {
		return this.http.patch<ApiResponse>(`${this.baseURL}/admin/networks/${networkId}/members/${memberId}`, data);
	}
}
